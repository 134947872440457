import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  state = {
    opened: new BehaviorSubject<boolean>(false),
  }

  constructor() { }

  get opened() {
    return this.state.opened.asObservable();
  }

  toggle(state: boolean) {
    this.state.opened.next(state);
  }

  open() {
    this.toggle(true);
  }

  close() {
    this.toggle(false);
  }
}
