import { Injectable } from '@angular/core';
import {CropperType} from '../../shared/components/cropper/cropper.component';
import {BehaviorSubject, Observable} from 'rxjs';
import {first} from 'rxjs/operators';

export interface CropperInstanceOptions {
  type: CropperType;
  file: any;
}

export enum CropperEvent {
  None = '',
  Open = 'open',
  Close = 'close',
  Crop = 'crop',
}

@Injectable({
  providedIn: 'root'
})
export class CropperService {
  state = {
    opened: new BehaviorSubject<CropperInstanceOptions>(null),
    closed: new BehaviorSubject<number>(0),
    crop: new BehaviorSubject<any>(null),
    event: new BehaviorSubject<{ event: CropperEvent, data?: any }>({ event: CropperEvent.None }),
  }

  constructor() { }

  get opened(): Observable<CropperInstanceOptions> {
    return this.state.opened.asObservable();
  }

  get event(): Observable<{ event: CropperEvent, data?: any }> {
    return this.state.event.asObservable();
  }

  get closed() {
    return this.state.closed.asObservable();
  }

  init(options: CropperInstanceOptions): Promise<any> {
    this.emit(CropperEvent.Open, options);
    return new Promise(resolve => {
      this.state.event.pipe(first(({ event }) => event === CropperEvent.Crop)).subscribe(({ data }) => resolve(data));
    });
  }

  emit(event: CropperEvent, data?: any) {
    this.state.event.next({ event, data });
  }
}
