import { Component, OnInit } from '@angular/core';
import {PreloaderService} from '../../../core/services/preloader.service';

@Component({
  selector: 'cto-preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.scss']
})
export class PreloaderComponent implements OnInit {
  shown = false;

  constructor(
    private loader: PreloaderService,
  ) { }

  ngOnInit(): void {
    this.loader.shown.subscribe(shown => {
      this.shown = shown;
    });
  }
}
