import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../core/services/auth.service';
import {Router} from '@angular/router';
import {GlobalService} from '../../../core/services/global.service';
import {SidebarService} from '../../../core/services/sidebar.service';
import {StoreKey, StoreService} from '../../../core/services/store.service';
import {UserProfile} from '../../../core/interfaces/user.interface';

@Component({
  selector: 'cto-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  state = {
    loggedIn: false,
    navigation: true,
    shown: true,
  };

  sidebarOpened = false;

  constructor(
    public auth: AuthService,
    private router: Router,
    private global: GlobalService,
    private sidebar: SidebarService,
    private store: StoreService,
  ) { }

  ngOnInit(): void {
    this.store.get<UserProfile>(StoreKey.UserProfile).subscribe(profile => {
      this.state.loggedIn = !!profile;
    });

    this.global.navigation.subscribe(shown => this.state.navigation = shown);
    this.global.header.subscribe(shown => this.state.shown = shown);

    this.sidebar.opened.subscribe(opened => {
      this.sidebarOpened = opened;
    })
  }

  toggleSidebar() {
    this.sidebarOpened
      ? this.sidebar.close()
      : this.sidebar.open();
  }
}
