import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {CardService} from '../../../core/services/card.service';
import {EditCardModalService} from '../../../core/services/edit-card-modal.service';
import {first} from 'rxjs/operators';
import {CardResponse} from '../../../core/interfaces/card.interface';
import {Subscription} from 'rxjs';

@Component({
  selector: 'cto-canonical-card-page-wrapper',
  templateUrl: './canonical-card-page-wrapper.component.html',
  styleUrls: ['./canonical-card-page-wrapper.component.scss']
})
export class CanonicalCardPageWrapperComponent implements OnInit, OnDestroy {
  editSub: Subscription;

  state = {
    handle: '',
    notFound: false,
    data: null,
  }

  constructor(
    private route: ActivatedRoute,
    private card: CardService,
    private edit: EditCardModalService,
  ) { }

  ngOnInit(): void {
    this.route.params.pipe(first()).subscribe(async ({ handle }) => {
      if(!handle) {
        return;
      }

      this.state.handle = handle;

      await this.getCardBySlug();
    });

    this.editSub = this.edit.onSave.subscribe(async () => {
      await this.getCardBySlug();
    });
  }

  getCardBySlug() {
    return new Promise(resolve => {
      this.card.getCardBySlug(this.state.handle).pipe(first()).subscribe((response: CardResponse) => {
        console.log(`Got information for card "${this.state.handle}":`, response);

        this.state.data = response;

        resolve(true);
      }, () => {
        this.navigateTo404();

        resolve(true);
      });
    });
  }

  navigateTo404() {
    this.state.notFound = true;
  }

  ngOnDestroy() {
    this.editSub?.unsubscribe();
  }
}
