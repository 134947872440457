import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {BankAccount} from '../interfaces/bank-account';
import {tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {MerchantService} from './merchant.service';
import {environment} from '../../../environments/environment';
import {StoreKey, StoreService} from './store.service';

@Injectable({
  providedIn: 'root'
})
export class BankService {
  state = {
    bank: new ReplaySubject<BankAccount>(1),
  };

  constructor(
    private http: HttpClient,
    private merchant: MerchantService,
    private store: StoreService,
  ) { }

  get bank(): Observable<BankAccount> {
    return this.store.get<BankAccount>(StoreKey.Bank);
  }

  getBankAccount(): Observable<BankAccount> {
    return this.http.get<BankAccount>(`${environment.url.tapper}/v1/merchants/${this.store.value<string>(StoreKey.MerchantId) || ''}/bank_account`)
      .pipe(tap(bank => {
        this.store.set<BankAccount>(StoreKey.Bank, bank);
      }));
  }

  createBankAccount(bank: BankAccount): Observable<BankAccount> {
    return this.http.post<BankAccount>(`${environment.url.tapper}/v1/merchants/${this.store.value<string>(StoreKey.MerchantId) || ''}/bank_account`, bank)
      .pipe(tap(bank => {
        this.store.set<BankAccount>(StoreKey.Bank, bank);
      }));
  }
}
