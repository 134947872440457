export interface Artist {
  name: string;
  description: string;
  avatar: string;
  images: string[];
  video?: string;
  slug?: string;
  realSlug: string;
  matcher?: {
    name: string;
  }
  social?: {
    website?: string;
    facebook?: string;
    instagram?: string;
    twitter?: string;
    linkedin?: string;
    tiktok?: string;
  },
  collection?: {
    color: string;
    description: string;
    logo: string;
    name: string;
  }
}

export interface ArtistData {
  [key: string]: Artist;
}

export const ARTISTS: ArtistData = {
  'blaire': {
    name: 'Blaire Hawes',
    description: `I am interested in people: in their lives, their dreams, their spirit. I take pictures to tell some small part of their story, by the way I frame my compositions intimately, in close focus. With Contribute.to I would like to create a portrait of “A Human” that is made up of hundreds of my portraits. This composite will be made up of people of all ages, races, ability, and sexes. I am doing this to show how we are all connected and related even in our differences. What’s more I will stitch the portraits physically together - like a quilt - to make one single work out of it. <br><br> My goal is to exhibit this work publicly and to raise funds for the production costs. I also get my daughters to help me on shoots, and I always pay them. They need to know they don’t ever work for free. Please back me!`,
    avatar: 'https://contribute.to/cdn/615c6fe0b4e1480012efdb83',
    images: [
      '/assets/data/artists/blairehawes-artist/images/image01.jpeg',
      '/assets/data/artists/blairehawes-artist/images/image02.jpeg',
      '/assets/data/artists/blairehawes-artist/images/image03.jpeg',
      '/assets/data/artists/blairehawes-artist/images/image04.jpeg',
      '/assets/data/artists/blairehawes-artist/images/image05.jpeg',
    ],
    video: 'https://contribute.to/cdn/creator_videos/Blaire_Vertical_compressed.mp4',
    social: {
      website: 'https://www.imagesbyblairecatherine.com/',
      instagram: 'https://www.instagram.com/imagesbyblairecatherine/',
    },
    realSlug: 'blairehawes',
    matcher: {
      name: 'Nicola Erni',
    }
  }
};

export const SHARED: ArtistData = {
  'blaire-starbucks': {
    name: 'Blaire Hawes',
    description: `I am interested in people: in their lives, their dreams, their spirit. I take pictures to tell some small part of their story, by the way I frame my compositions intimately, in close focus. With Contribute.to I would like to create a portrait of “A Human” that is made up of hundreds of my portraits. This composite will be made up of people of all ages, races, ability, and sexes. I am doing this to show how we are all connected and related even in our differences. What’s more I will stitch the portraits physically together - like a quilt - to make one single work out of it. <br><br> My goal is to exhibit this work publicly and to raise funds for the production costs. I also get my daughters to help me on shoots, and I always pay them. They need to know they don’t ever work for free. Please back me!`,
    avatar: 'https://contribute.to/cdn/615c6fe0b4e1480012efdb83',
    images: [
      '/assets/data/artists/blairehawes-artist/images/image01.jpeg',
      '/assets/data/artists/blairehawes-artist/images/image02.jpeg',
      '/assets/data/artists/blairehawes-artist/images/image03.jpeg',
      '/assets/data/artists/blairehawes-artist/images/image04.jpeg',
      '/assets/data/artists/blairehawes-artist/images/image05.jpeg',
    ],
    video: 'https://contribute.to/cdn/creator_videos/Blaire_Vertical_compressed.mp4',
    social: {
      website: 'https://www.imagesbyblairecatherine.com/',
      instagram: 'https://www.instagram.com/imagesbyblairecatherine/',
    },
    realSlug: 'blairehawes',
    matcher: {
      name: 'Nicola Erni',
    },
    collection: {
      color: '#007042',
      logo: '/assets/data/artists/blairehawes-artist/images/starbucks-logo.png',
      name: 'Starbucks',
      description: 'Be the change you want to see in the world. Join Starbucks in supporting creators working to make a difference through their art.',
    }
  }
};
