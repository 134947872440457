import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PreloaderService {
  state = {
    shown: new BehaviorSubject<boolean>(false),
  }

  constructor() { }

  get shown() {
    return this.state.shown.asObservable();
  }

  show() {
    this.state.shown.next(true);
  }

  hide() {
    this.state.shown.next(false);
  }
}
