import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {VideoPlayerService} from '../../../core/services/video-player.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'cto-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit {
  @ViewChild('videoElement', { static: false }) videoElement: ElementRef;

  state = {
    shown: false,
    url: '',
    src: null,
  }

  instance: any;

  constructor(
    private player: VideoPlayerService,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    this.player.opened.subscribe(url => {
      this.state.shown = !!url;
      this.state.url = url + (url.includes('?') ? '&autoplay=1&loop=1&autopause=0' : '?autoplay=1&loop=1&autopause=0');

      if(this.state.url) {
        this.state.src = this.sanitizer.bypassSecurityTrustResourceUrl(this.state.url);
      } else {
        this.state.src = null;
      }

      setTimeout(() => {
        this.videoElement?.nativeElement.addEventListener('webkitendfullscreen', () => {
          this.close();
        });

        if(this.state.shown && this.videoElement?.nativeElement) {
          this.instance = new (window as any).Vimeo.Player(this.videoElement?.nativeElement);
        } else {
          this.instance = null;
        }
      }, 100);
    });
  }

  close() {
    this.player.close();
    this.instance = null;
  }
}
