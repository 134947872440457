import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VideoPlayerService {
  state = {
    opened: new BehaviorSubject<string>(''),
  }

  constructor() { }

  get opened() {
    return this.state.opened.asObservable();
  }

  open(url: string) {
    this.state.opened.next(url);
  }

  close() {
    this.state.opened.next('');
  }
}
