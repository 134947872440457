import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of, ReplaySubject} from 'rxjs';
import {catchError, first, tap} from 'rxjs/operators';
import {Card} from '../interfaces/card';
import {CardType} from '../enums/card-type';
import {environment} from '../../../environments/environment';
import {StoreKey, StoreService} from './store.service';
import {Merchant} from '../interfaces/merchant';
import {Matching} from '../interfaces/matching.interface';
import {CardResponse} from '../interfaces/card.interface';

@Injectable({
  providedIn: 'root'
})
export class CardService {
  state = {
    cards: new ReplaySubject<Card[]>(1),
    card: new ReplaySubject<Card>(1),
    poller: new ReplaySubject<string>(1),
  };

  constructor(
    private http: HttpClient,
    private store: StoreService,
  ) { }

  get cards() {
    return this.state.cards.asObservable();
  }

  get card() {
    return this.state.card.asObservable();
  }

  get poller() {
    return this.state.poller.asObservable();
  }

  getCard() {
    return this.http.get<CardResponse>(`${environment.url.tapper}/v1/cards/?merchant_id=${this.store.value<string>(StoreKey.MerchantId)}`)
      .pipe(
        tap((response: CardResponse) => {
          this.store.set<Card>(StoreKey.Card, response.card);
          this.store.set<string>(StoreKey.CardId, response.card.id);
          this.store.set<Matching>(StoreKey.Matching, response.matching_information);
        }),
        catchError(err => of(null)),
      );
  }

  getCards() {
    return this.http.get<Card[]>('/api/v1/cards/button/me')
      .pipe(tap(cards => {
        if(!cards?.length) {
          this.state.cards.next([]);
          this.state.card.next(null);
          return;
        }

        const card = cards[cards.length - 1];

        console.log('Fetching card:', card);

        this.state.cards.next(cards);
        this.state.card.next(card);
      }));
  }

  createCard(data: Partial<Card>, type: CardType) {
    return this.http.post<Card>(`/api/v1/cards/${type}`, data).pipe(tap(card => {
      console.log('created card', card);
      this.state.card.next(card);
    }));
  }

  updateCard(cardId: string, data: Partial<Card>, type: CardType): Observable<Card> {
    return this.http.put<Card>(`/api/v1/cards/${type}/${cardId}`, data).pipe(tap(card => {
      this.state.card.next(card);
    }))
  }

  createCardV2(data: any) {
    return this.http.post(`${environment.url.tapper}/v1/cards/?merchant_id=${this.store.value<Merchant>(StoreKey.Merchant)?.id}`, data);
  }

  getCardBySlug(slug: string) {
    return this.http.get<CardResponse>(`${environment.url.tapper}/v1/cards/${slug}`);
  }

  updateCardV2(data: Partial<Card>) {
    return this.http.patch<CardResponse>(`${environment.url.tapper}/v1/cards/${this.store.value<string>(StoreKey.CardId)}`, data)
      .pipe(
        tap(response => {
          this.store.set<Card>(StoreKey.Card, response.card);
          this.store.set<string>(StoreKey.CardId, response.card.id);
          this.store.set<Matching>(StoreKey.Matching, response.matching_information);
        }),
      );
  }

  pollImages(id: string) {
    let timeout;

    this.state.poller.next(id);

    const poller = () => {
      if(timeout) {
        clearTimeout(timeout);
      }

      // this.getCards().pipe(first()).subscribe(cards => {
      //   if(!cards.length) {
      //     this.state.poller.next('');
      //     return;
      //   }
      //
      //   const card = cards[cards.length - 1];
      //
      //   if(!card.ctologoImage && !card.buttoncardcoverImage) {
      //     this.state.poller.next('');
      //     return;
      //   }
      //
      //   if(card.ctologoImage?.publicUrl && card.buttoncardcoverImage?.publicUrl) {
      //     this.state.poller.next('');
      //     return;
      //   }
      //
      //   console.log('Polling...', card.ctologoImage);
      //   timeout = setTimeout(poller, 500);
      // });
    }

    poller();
  }
}
