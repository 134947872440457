import { Component, OnInit } from '@angular/core';
import {FaqService} from '../../../core/services/faq.service';
import {QuestionGroup} from '../../../core/interfaces/faq';
import {first} from 'rxjs/operators';

@Component({
  selector: 'cto-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  groups: QuestionGroup[] = [];

  constructor(
    private faq: FaqService,
  ) { }

  ngOnInit(): void {
    this.faq.getQuestionGroups().pipe(first()).subscribe(groups => {
      this.groups = groups;
    })
  }

}
