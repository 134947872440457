/*
AT = "AT"  # Austria
BE = "BE"  # Belgium
BG = "BG"  # Bulgaria
CY = "CY"  # Cyprus
CZ = "CZ"  # Czech Republic
DK = "DK"  # Denmark
EE = "EE"  # Estonia
FI = "FI"  # Finland
FR = "FR"  # France
DE = "DE"  # Germany
GR = "GR"  # Greece
HU = "HU"  # Hungary
IE = "IE"  # Ireland
IT = "IT"  # Italy
LV = "LV"  # Latvia
LT = "LT"  # Lithuania
LU = "LU"  # Luxembourg
MT = "MT"  # Malta
NL = "NL"  # Netherlands
NO = "NO"  # Norway
PL = "PL"  # Poland
PT = "PT"  # Portugal
RO = "RO"  # Romania
SK = "SK"  # Slovakia
SI = "SI"  # Slovenia
ES = "ES"  # Spain
SE = "SE"  # Sweden
CH = "CH"  # Switzerland
GB = "GB"  # UK
US = "US"  # US
 */

import {Country} from '../interfaces/country';

export const countries: Country[] = [
  {
    code: 'at',
    name: 'Austria',
    currencies: ['EUR'],
  },
  {
    code: 'be',
    name: 'Belgium',
    currencies: ['EUR'],
  },
  {
    code: 'bg',
    name: 'Bulgaria',
    currencies: ['BGN'],
  },
  {
    code: 'ca',
    name: 'Canada',
    currencies: ['CAD'],
    fields: {
      routing: true,
    },
  },
  {
    code: 'cy',
    name: 'Cyprus',
    currencies: ['EUR'],
  },
  {
    code: 'cz',
    name: 'Czech Republic',
    currencies: ['CZK'],
  },
  {
    code: 'dk',
    name: 'Denmark',
    currencies: ['DKK'],
  },
  {
    code: 'ee',
    name: 'Estonia',
    currencies: ['EUR'],
  },
  {
    code: 'fi',
    name: 'Finland',
    currencies: ['EUR'],
  },
  {
    code: 'fr',
    name: 'France',
    currencies: ['EUR'],
  },
  {
    code: 'de',
    name: 'Germany',
    currencies: ['EUR'],
  },
  {
    code: 'gr',
    name: 'Greece',
    currencies: ['EUR'],
  },
  {
    code: 'hu',
    name: 'Hungary',
    currencies: ['EUR'],
  },
  {
    code: 'ie',
    name: 'Ireland',
    currencies: ['EUR'],
  },
  {
    code: 'it',
    name: 'Italy',
    currencies: ['EUR'],
  },
  {
    code: 'lv',
    name: 'Latvia',
    currencies: ['EUR'],
  },
  {
    code: 'lt',
    name: 'Lithuania',
    currencies: ['EUR'],
  },
  {
    code: 'lu',
    name: 'Luxembourg',
    currencies: ['EUR'],
  },
  {
    code: 'mt',
    name: 'Malta',
    currencies: ['EUR'],
  },
  {
    code: 'nl',
    name: 'Netherlands',
    currencies: ['EUR'],
  },
  {
    code: 'no',
    name: 'Norway',
    currencies: ['NOK'],
  },
  {
    code: 'pl',
    name: 'Poland',
    currencies: ['EUR'],
  },
  {
    code: 'pt',
    name: 'Portugal',
    currencies: ['EUR'],
  },
  {
    code: 'ro',
    name: 'Romania',
    currencies: ['RON'],
  },
  {
    code: 'sk',
    name: 'Slovakia',
    currencies: ['EUR'],
  },
  {
    code: 'si',
    name: 'Slovenia',
    currencies: ['EUR'],
  },
  {
    code: 'es',
    name: 'Spain',
    currencies: ['EUR'],
  },
  {
    code: 'se',
    name: 'Sweden',
    currencies: ['SEK'],
  },
  {
    code: 'ch',
    name: 'Switzerland',
    currencies: ['CHF'],
  },
  {
    code: 'gb',
    name: 'United Kingdom',
    currencies: ['GBP'],
  },
  {
    code: 'us',
    name: 'United States',
    currencies: ['USD'],
    fields: {
      routing: true,
    },
  },
];
