const priceRegex = /^[0-9,]+(?:\.\d{1,2})?$/;

export default class StringUtils {
  static priceStringToNumber(price: string | number, int = false) {
    if(price == null) {
      return Number.NaN;
    }

    if(typeof price === 'number') {
      return price;
    }

    const trimmed = price.replace(/\s/g, '');

    if (!priceRegex.test(trimmed)) {
      return Number.NaN;
    }

    return int ? Number.parseInt(trimmed.replace(/,/g, ''), 10) : Number.parseFloat(trimmed.replace(/,/g, ''));
  }
}
