<div class="container faq">
  <ng-container *ngFor="let group of groups">
    <div class="faq__group">
      <div [outerHTML]="group.title"></div>
      <ng-container *ngFor="let question of group.questions">
        <div class="faq__question">
          <div [outerHTML]="question.title"></div>
          <div [outerHTML]="question.answer"></div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
<cto-footer></cto-footer>
