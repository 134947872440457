import {Directive, EventEmitter, HostListener, Input, OnDestroy, Output} from '@angular/core';
import {FilePickerOptions} from '../interfaces/file-picker-options';

@Directive({
  selector: '[ctoFilePicker]'
})
export class FilePickerDirective implements OnDestroy {
  @Input() options: FilePickerOptions;
  @Output() change: EventEmitter<FileList> = new EventEmitter<FileList>();

  private input: HTMLInputElement;

  constructor() { }

  @HostListener('click')
  onClick() {
    this.initFilePicker();
  }

  private initFilePicker() {
    this.input = document.createElement('input');

    this.input.type = 'file';

    if(this.options) {
      if(this.options.multiple) {
        this.input.multiple = this.options.multiple;
      }

      if(this.options.accept) {
        this.input.accept = this.options.accept;
      }
    }

    this.input.addEventListener('change', () => {
      this.change.emit(this.input.files);
    });

    this.input.click();
  }

  ngOnDestroy() {
    this.input?.remove();
  }
}
