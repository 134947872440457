<div class="image-cropper" [class.is-opened]="opened">
  <div class="image-cropper__backdrop"></div>
  <div class="image-cropper__body">
    <div class="image-cropper__content">
      <div class="image-cropper__image-wrapper">
        <img #imageElement [src]="src" alt="" class="image-cropper__image">
      </div>
    </div>
    <div class="image-cropper__actions">
      <button type="button" class="button button--link" (click)="cancel()">Cancel</button>
      <button type="button" class="button button--primary" (click)="done()">Done</button>
    </div>
  </div>
</div>

