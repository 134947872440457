import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of, ReplaySubject} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError, tap} from 'rxjs/operators';
import {Slug} from '../interfaces/slug';
import {Card} from '../interfaces/card';
import {StoreKey, StoreService} from './store.service';
import {Merchant} from '../interfaces/merchant';
import {SlugResponse} from '../interfaces/slug.interface/slug-response.interface';

@Injectable({
  providedIn: 'root'
})
export class SlugService {
  state = {
    slug: new ReplaySubject<Slug>(1),
  }

  constructor(
    private http: HttpClient,
    private store: StoreService,
  ) { }

  private static createValidationError(message: string): any {
    return { error: message };
  }

  get slug() {
    return this.state.slug.asObservable();
  }

  claim(slug: string) {
    return this.http.post<SlugResponse>(`${environment.url.tapper}/v1/cards/slugs`, {
      slug,
      merchant_id: this.store.value<string>(StoreKey.MerchantId),
    })
  }

  check(name: string) {
    if(!name?.length) {
      return of(SlugService.createValidationError('Handle can not be empty.'));
    }

    if (name.length < 3) {
      return of(SlugService.createValidationError('The handle must be at least 3 characters long.'));
    }

    if (!/^[a-zA-Z0-9-]*$/ig.test(name)) {
      return of(SlugService.createValidationError('The handle contains illegal characters.'));
    }

    if (environment.blacklist.slugs.includes(name.toLowerCase())) {
      return of(SlugService.createValidationError('This handle is protected. If you want to claim it, please contact us.'));
    }

    return of({ status: 'ok' });

    // return this.http.post('/api/v1/slugs/check', { name })
    //   .pipe(
    //     catchError((err: { error: { message: string }[] }) => {
    //       return of({ error: err.error[0].message });
    //     }),
    //   );
  }

  getSlugs() {
    return this.http.get<Slug[]>('/api/v1/slugs/me')
      .pipe(tap(slugs => {
        console.log('got slugs', slugs);

        if(!slugs?.length) {
          this.state.slug.next(null);
          return;
        }

        this.state.slug.next(slugs[0]);
      }));
  }

  createSlug(data: Partial<Slug>): Observable<{ slug: Slug }> {
    return this.http.post<{ slug: Slug }>('/api/v1/slugs', data);
  }

  updateSlug(id: string, data: Partial<Slug>): Observable<{ slug: Slug }> {
    return this.http.put<{ slug: Slug }>(`/api/v1/slugs/${id}`, data);
  }

  getContentForSlug(slug: string): Observable<{ card: Card; slug: Partial<Slug>; }> {
    return this.http.get<{ card: Card; slug: Partial<Slug>; }>(`/api/v1/slugs/${slug}/content`);
  }
}
