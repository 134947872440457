import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EditCardModalService {
  state = {
    opened: new BehaviorSubject<boolean>(false),
    saved: new ReplaySubject<any>(),
  };

  constructor() { }

  get opened() {
    return this.state.opened.asObservable();
  }

  get onSave(): Observable<any> {
    return this.state.saved.asObservable();
  }

  toggle(opened: boolean) {
    this.state.opened.next(opened);
  }

  open() {
    this.toggle(true);
  }

  close() {
    this.toggle(false);
  }

  saved(data: any) {
    this.state.saved.next(data);
  }
}
