import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cto-artvent',
  templateUrl: './artvent.component.html',
  styleUrls: ['./artvent.component.scss']
})
export class ArtventComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
