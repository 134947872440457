<div class="container faq">
  <ng-container *ngFor="let group of groups">
    <div class="faq__group">
      <div [outerHTML]="group.title"></div>
      <div class="faq__question faq__question--condensed">
        <div [outerHTML]="group.content"></div>
      </div>
    </div>
  </ng-container>
</div>
<cto-footer></cto-footer>
