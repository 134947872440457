<div class="video-player" [class.is-shown]="state.shown">
<!--  <video *ngIf="state.shown" #videoElement [src]="src" class="video-player__player" controls autoplay></video>-->
  <iframe
    *ngIf="state.shown"
    #videoElement
    [src]="state.src"
    class="video-player__player"
    allow="autoplay; fullscreen; picture-in-picture"
    webkitallowfullscreen
    mozallowfullscreen
    allowfullscreen
    frameborder="0"
  ></iframe>
  <button type="button" class="video-player__close" (click)="close()">
    <svg class="video-player__close-icon" viewBox="0 0 24 24">
      <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
    </svg>
  </button>
</div>
