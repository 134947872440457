import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, of, ReplaySubject} from 'rxjs';
import {tap} from 'rxjs/operators';
import {MerchantAccount} from '../interfaces/merchant-account';
import {environment} from '../../../environments/environment';
import {UserResource} from '../interfaces/user-resource';
import {StoreKey, StoreService} from './store.service';
import {Merchant} from '../interfaces/merchant.interface';

@Injectable({
  providedIn: 'root'
})
export class MerchantService {
  state = {
    merchant: new ReplaySubject<Merchant>(1),
    account: new ReplaySubject<MerchantAccount>(1),
    balance: new ReplaySubject<any>(1),
    history: new ReplaySubject<any>(1),
    requirements: new ReplaySubject<any>(1),
    resource: new BehaviorSubject<UserResource>(null),
  }

  constructor(
    private http: HttpClient,
    private store: StoreService,
  ) { }

  get merchant(): Observable<Merchant> {
    return this.state.merchant.asObservable();
  }

  get requirements(): Observable<any> {
    return this.state.requirements.asObservable();
  }

  get balance(): Observable<any> {
    return this.state.balance.asObservable();
  }

  get history(): Observable<any> {
    return this.state.history.asObservable();
  }

  get account(): Observable<MerchantAccount> {
    return this.state.account.asObservable();
  }

  get merchantId(): string {
    return this.store.value<string>(StoreKey.MerchantId) || '';
  }

  createMerchant() {
    return this.http.post<Merchant>(`${environment.url.tapper}/v1/merchants`, {})
      .pipe(tap(merchant => {
        this.store.set<Merchant>(StoreKey.Merchant, merchant);
        this.store.set<string>(StoreKey.MerchantId, merchant.id);
      }));
  }

  getMerchant(id?: string): Observable<Merchant> {
    if(!id && !this.merchantId) {
      this.store.set<Merchant>(StoreKey.Merchant, null);
      this.state.merchant.next(null);
      return of(null);
    }

    return this.http.get<Merchant>(`${environment.url.tapper}/v1/merchants/${id || this.merchantId}`)
      .pipe(tap(merchant => {
        this.state.merchant.next(merchant);
        this.store.set<Merchant>(StoreKey.Merchant, merchant);
        this.store.set<string>(StoreKey.MerchantId, merchant.id);
      }));
  }

  getRequirements(): Observable<any> {
    return this.http.get<any>(`${environment.url.tapper}/v1/merchants/${this.merchantId}/requirements`)
      .pipe(tap(requirements => this.state.requirements.next(requirements)));
  }

  getBalance(): Observable<any> {
    return this.http.get<any>(`${environment.url.tapper}/v1/merchants/${this.merchantId}/balance`)
      .pipe(tap(balance => this.state.balance.next(balance)));
  }

  getTransactionHistory(): Observable<any> {
    const from = new Date();
    const now = new Date();

    from.setDate(now.getDate() - 30);

    return this.http.get<any>(`${environment.url.tapper}/v1/purchases`, {
      params: {
        merchant_id: this.merchantId,
        tab_status: 'closed',
        // purchase_date_from: from.toISOString().split('T')[0],
        // purchase_date_to: now.toISOString().split('T')[0],
      }
    })
      .pipe(tap(history => this.state.history.next(history)));
  }

  updateMerchant(data: any, id?: string) {
    return this.http.patch<Merchant>(`${environment.url.tapper}/v1/merchants/${id || this.merchantId}`, data)
      .pipe(tap(merchant => {
        this.state.merchant.next(merchant);
        this.store.set<Merchant>(StoreKey.Merchant, merchant);
        this.store.set<string>(StoreKey.MerchantId, merchant.id);
      }));
  }

  getRoles(): Observable<UserResource[]> {
    return this.http.get<UserResource[]>(`${environment.url.tapper}/v1/auth/roles/me`)
      .pipe(tap(resources => {
        this.store.set<string>(StoreKey.MerchantId, resources?.length ? resources[0].resource_id : '');

        if(!resources?.length) {
          return;
        }

        this.state.resource.next(resources[0]);
      }));
  }
}
