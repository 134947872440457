<div class="modal" *ngIf="state.modal.opened">
  <div class="modal__backdrop"></div>
  <div class="modal__body">
    <div class="modal__header">
      <h3 class="modal__title">Edit Card</h3>
      <div class="modal__right-action">
        <button type="button" class="modal__button-action" (click)="close()">
          <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.0821739 12.9175C0.137565 12.9723 0.209912 13 0.282259 13C0.354606 13 0.426953 12.9723 0.482343 12.9175L6.49958 6.89967L12.5168 12.9169C12.5722 12.9723 12.6446 13 12.7169 13C12.7893 13 12.8616 12.9723 12.917 12.9175C13.0272 12.8073 13.0272 12.6281 12.917 12.5179L6.89919 6.50007L12.917 0.482832C13.0272 0.372615 13.0272 0.193443 12.917 0.0832273C12.8068 -0.0269889 12.6276 -0.0269889 12.5174 0.0832273L6.49958 6.10047L0.482343 0.0826621C0.372127 -0.027554 0.192955 -0.027554 0.0827391 0.0826621C-0.0274771 0.192878 -0.0274771 0.37205 0.0827391 0.482267L6.09998 6.50007L0.0821739 12.5173C-0.0280423 12.6281 -0.0280423 12.8067 0.0821739 12.9175Z" fill="#5B5B5B"/>
          </svg>
        </button>
      </div>
    </div>
    <div class="slide-layout" style="padding: 0;">
      <div class="slide-layout__body" style="padding-top: 0;">
        <div @myInsertRemoveTrigger *ngIf="state.modal.screen === 1" class="slide-layout__slide">
          <div ctoFilePicker class="slide-layout__cover-wrapper" (change)="onCoverChange($event)" [options]="filePickerOptions">
            <div class="slide-layout__cover-holder">
              <img [src]="cover || '/assets/images/hero-default.png'" alt="Card hero image." class="slide-layout__cover">
              <button class="slide-layout__action-button">
                <svg *ngIf="!cover" width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g opacity="0.8" filter="url(#filter0_d)">
                    <circle cx="24.5" cy="20.5" r="14.5" fill="white"/>
                  </g>
                  <path d="M18.5 21.5H23.5V26.5C23.5 27.0521 23.9479 27.5 24.5 27.5C25.0521 27.5 25.5 27.0521 25.5 26.5V21.5H30.5C31.0521 21.5 31.5 21.0521 31.5 20.5C31.5 19.9479 31.0521 19.5 30.5 19.5H25.5V14.5C25.5 13.9479 25.0521 13.5 24.5 13.5C23.9479 13.5 23.5 13.9479 23.5 14.5V19.5H18.5C17.9479 19.5 17.5 19.9479 17.5 20.5C17.5 21.0521 17.9479 21.5 18.5 21.5Z" fill="#602576" stroke="#602576"/>
                  <defs>
                    <filter id="filter0_d" x="0" y="0" width="49" height="49" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                      <feOffset dy="4"/>
                      <feGaussianBlur stdDeviation="5"/>
                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                    </filter>
                  </defs>
                </svg>
                <svg *ngIf="cover" width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g opacity="0.8" filter="url(#filter0_d)">
                    <circle cx="24.5" cy="20.5" r="14.5" fill="white"/>
                  </g>
                  <path d="M28.0506 13.6575L18.414 23.3079C18.3551 23.3669 18.3111 23.4388 18.2843 23.5171L17.0275 27.294C16.9632 27.4871 17.0136 27.6996 17.1572 27.8433C17.259 27.9453 17.3961 28 17.5354 28C17.5922 28 17.649 27.9903 17.7047 27.9732L21.4762 26.7146C21.5555 26.6888 21.6273 26.6438 21.6851 26.5847L31.3228 16.9333C31.7589 16.4955 32 15.9139 32 15.2948C32 14.6757 31.7589 14.0942 31.3228 13.6575C30.4475 12.7808 28.9249 12.7808 28.0506 13.6575ZM30.5653 16.1747L21.0176 25.736L18.3829 26.6159L19.2615 23.9774L28.8092 14.4161C29.2785 13.9461 30.096 13.9461 30.5664 14.4161C30.7989 14.651 30.9285 14.9633 30.9285 15.2948C30.9285 15.6264 30.7989 15.9397 30.5653 16.1747Z" fill="#602576"/>
                  <defs>
                    <filter id="filter0_d" x="0" y="0" width="49" height="49" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                      <feOffset dy="4"/>
                      <feGaussianBlur stdDeviation="5"/>
                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                    </filter>
                  </defs>
                </svg>
              </button>
            </div>
          </div>
          <div ctoFilePicker class="slide-layout__avatar-wrapper" (change)="onFileChange($event)" [options]="filePickerOptions" style="margin-top: -42px; margin-left: 25px;">
            <img [src]="avatar || '/assets/images/avatar-default.svg'" alt="User avatar" class="slide-layout__avatar">
            <button class="slide-layout__action-button slide-layout__action-button--avatar">
              <svg *ngIf="!avatar" width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.8" filter="url(#filter0_d)">
                  <circle cx="24.5" cy="20.5" r="14.5" fill="white"/>
                </g>
                <path d="M18.5 21.5H23.5V26.5C23.5 27.0521 23.9479 27.5 24.5 27.5C25.0521 27.5 25.5 27.0521 25.5 26.5V21.5H30.5C31.0521 21.5 31.5 21.0521 31.5 20.5C31.5 19.9479 31.0521 19.5 30.5 19.5H25.5V14.5C25.5 13.9479 25.0521 13.5 24.5 13.5C23.9479 13.5 23.5 13.9479 23.5 14.5V19.5H18.5C17.9479 19.5 17.5 19.9479 17.5 20.5C17.5 21.0521 17.9479 21.5 18.5 21.5Z" fill="#602576" stroke="#602576"/>
                <defs>
                  <filter id="filter0_d" x="0" y="0" width="49" height="49" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                    <feOffset dy="4"/>
                    <feGaussianBlur stdDeviation="5"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                  </filter>
                </defs>
              </svg>
              <svg *ngIf="avatar" width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.8" filter="url(#filter0_d)">
                  <circle cx="24.5" cy="20.5" r="14.5" fill="white"/>
                </g>
                <path d="M28.0506 13.6575L18.414 23.3079C18.3551 23.3669 18.3111 23.4388 18.2843 23.5171L17.0275 27.294C16.9632 27.4871 17.0136 27.6996 17.1572 27.8433C17.259 27.9453 17.3961 28 17.5354 28C17.5922 28 17.649 27.9903 17.7047 27.9732L21.4762 26.7146C21.5555 26.6888 21.6273 26.6438 21.6851 26.5847L31.3228 16.9333C31.7589 16.4955 32 15.9139 32 15.2948C32 14.6757 31.7589 14.0942 31.3228 13.6575C30.4475 12.7808 28.9249 12.7808 28.0506 13.6575ZM30.5653 16.1747L21.0176 25.736L18.3829 26.6159L19.2615 23.9774L28.8092 14.4161C29.2785 13.9461 30.096 13.9461 30.5664 14.4161C30.7989 14.651 30.9285 14.9633 30.9285 15.2948C30.9285 15.6264 30.7989 15.9397 30.5653 16.1747Z" fill="#602576"/>
                <defs>
                  <filter id="filter0_d" x="0" y="0" width="49" height="49" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                    <feOffset dy="4"/>
                    <feGaussianBlur stdDeviation="5"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                  </filter>
                </defs>
              </svg>
            </button>
          </div>
          <form class="slide-layout__form" (ngSubmit)="cardSubmit()" [formGroup]="forms.card" style="padding: 25px 25px 62px;">
            <div class="form-group">
              <input type="text" class="form-text" placeholder="Name" formControlName="title" maxlength="64">
              <div class="form-counter">{{ nameCharacters }} characters left</div>
              <span *ngIf="!global.isControlValid(forms.card, 'title')" class="form-message form-message--error">Name needs to be between 4 and 64 characters.</span>
            </div>
            <div class="form-group">
              <textarea class="form-text" placeholder="Description" rows="4" formControlName="summary" maxlength="1000"></textarea>
              <div class="form-counter">{{ descCharacters }} characters left</div>
              <span *ngIf="!global.isControlValid(forms.card, 'summary')" class="form-message form-message--error">Description needs to be between 4 and 1000 characters.</span>
            </div>
            <div class="form-group">
              <input type="text" class="form-text" placeholder="Vimeo URL" formControlName="video_url">
            </div>
            <div class="form-group" style="margin-top: 30px;">
              <label class="form-title-label">Set Contribution Amounts</label>
              <div formArrayName="contribution_amounts" class="form-price-inputs">
                <div formGroupName="0" class="form-price-input-group">
                  <input type="text" class="form-price-input" formControlName="price">
                  <span class="form-price-prefix">$</span>
                </div>
                <div formGroupName="1" class="form-price-input-group">
                  <input type="text" class="form-price-input" formControlName="price">
                  <span class="form-price-prefix">$</span>
                </div>
                <div formGroupName="2" class="form-price-input-group">
                  <input type="text" class="form-price-input" formControlName="price">
                  <span class="form-price-prefix">$</span>
                </div>
              </div>
            </div>
            <div style="text-align: center; margin-top: 5px; margin-bottom: 30px;">
              <span *ngIf="!forms.card.get('contribution_amounts').valid" class="form-message form-message--error">Please enter valid contribution amounts. <br> Minimum of $2 and maximum of $10,000.</span>
            </div>
            <div style="margin-bottom: 30px;">
              <div class="form-group">
                <input type="text" class="form-text" placeholder="https://website.com/" formControlName="social_website_url" [class.invalid]="!isLinkValid('website')">
              </div>
              <div class="form-group form-group--prefix">
                <div class="form-group__wrapper">
                  <input type="hidden" formControlName="social_facebook_url">
                  <input type="text" #facebookUsername class="form-text" [value]="form.social_facebook_username" (paste)="onPasteChange('facebook', $event)" (input)="onInputChange('facebook', facebookUsername.value)" [class.invalid]="errors.facebook.status">
                  <span class="form-group__prefix" [class.invalid]="errors.facebook.status">{{ socialMedia.facebook }}</span>
                </div>
                <span *ngIf="errors.facebook.status" class="form-message form-message--error">{{ errors.facebook.message }}</span>
              </div>
              <div class="form-group form-group--prefix">
                <div class="form-group__wrapper">
                  <input type="hidden" formControlName="social_instagram_url">
                  <input type="text" #instagramUsername class="form-text" [value]="form.social_instagram_username" (paste)="onPasteChange('instagram', $event)" (input)="onInputChange('instagram', instagramUsername.value)" [class.invalid]="errors.instagram.status">
                  <span class="form-group__prefix" [class.invalid]="errors.instagram.status">{{ socialMedia.instagram }}</span>
                </div>
                <span *ngIf="errors.instagram.status" class="form-message form-message--error">{{ errors.instagram.message }}</span>
              </div>
              <div class="form-group form-group--prefix">
                <div class="form-group__wrapper">
                  <input type="hidden" formControlName="social_twitter_url">
                  <input type="text" #twitterUsername class="form-text" [value]="form.social_twitter_username" (paste)="onPasteChange('twitter', $event)" (input)="onInputChange('twitter', twitterUsername.value)" [class.invalid]="errors.twitter.status">
                  <span class="form-group__prefix" [class.invalid]="errors.twitter.status">{{ socialMedia.twitter }}</span>
                </div>
                <span *ngIf="errors.twitter.status" class="form-message form-message--error">{{ errors.twitter.message }}</span>
              </div>
              <div class="form-group form-group--prefix">
                <div class="form-group__wrapper">
                  <input type="hidden" formControlName="social_tiktok_url">
                  <input type="text" #tiktokUsername class="form-text" [value]="form.social_tiktok_username" (paste)="onPasteChange('tiktok', $event)" (input)="onInputChange('tiktok', tiktokUsername.value)" [class.invalid]="errors.tiktok.status">
                  <span class="form-group__prefix" [class.invalid]="errors.tiktok.status">{{ socialMedia.tiktok }}</span>
                </div>
                <span *ngIf="errors.tiktok.status" class="form-message form-message--error">{{ errors.tiktok.message }}</span>
              </div>
              <div class="form-group form-group--prefix">
                <div class="form-group__wrapper">
                  <input type="hidden" formControlName="social_linkedin_url">
                  <input type="text" #linkedinUsername class="form-text" [value]="form.social_linkedin_username" (paste)="onPasteChange('linkedin', $event)" (input)="onInputChange('linkedin', linkedinUsername.value)" [class.invalid]="errors.linkedin.status">
                  <span class="form-group__prefix" [class.invalid]="errors.linkedin.status">{{ socialMedia.linkedin }}</span>
                </div>
                <span *ngIf="errors.linkedin.status" class="form-message form-message--error">{{ errors.linkedin.message }}</span>
              </div>
            </div>
            <div class="form-group form-group--float">
              <div class="form-actions">
                <button type="button" class="button button--full" (click)="close()">Cancel</button>
                <button class="button button--full button--primary" [disabled]="!forms.card.valid">Save</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
