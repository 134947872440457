<div class="homepage">
  <div class="section">
    <div class="container">
      <div class="hero">
        <h1 class="hero__title">
          Your story. Your passion. Your Card.
        </h1>
        <h2 class="hero__small-title">
          Raise money online for the thing you love doing.
        </h2>
<!--        <p class="hero__subtitle">-->
<!--          Get started within a minute.-->
<!--        </p>-->
        <div class="hero__animation">
          <div class="hero__animation-input">
            <span>contribute.to/</span><span class="hero__animation-label">{{ word }}</span>
          </div>
          <a class="button button--secondary button--large hero__button" (click)="auth.signup()">Get Started!</a>
        </div>
      </div>
    </div>
  </div>
  <section class="section">
    <div class="container container--mobile-full">
      <div class="card-preview">
        <div class="card-preview__url">
          <span>contribute.to/</span><a routerLink="/ozabu">ozabu</a>
        </div>
        <img src="/assets/images/image-ozabu.png" alt="ozabu image" class="card-preview__image">
        <div class="card-preview__card">
          <div class="card-preview__card-image-wrapper">
            <img src="/assets/images/card-ozabu.svg" alt="ozabu card" class="card-preview__card-image">
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section">
    <div class="container">
      <div class="title-list">
        <div class="title-list__title">
          A simple way to fund your passion
        </div>
        <div class="title-list__list">
          <ul class="title-list__list-items">
            <li class="title-list__list-item">Grab Your Unique Contribute.to Link</li>
            <li class="title-list__list-item">Build Your Contributions Card</li>
            <li class="title-list__list-item">Share Your Card with Your Supporters</li>
          </ul>
          <a class="button button--secondary button--large title-list__button" (click)="auth.signup()">Get Started!</a>
        </div>
      </div>
    </div>
  </section>
  <div class="section">
    <div class="text-image">
      <div class="text-image__content-wrapper">
        <div class="container container--mobile-full">
          <div class="text-image__content">
            <div class="text-image__image-wrapper">
              <img src="/assets/images/social-share.png" alt="Card social sharing" class="text-image__image">
            </div>
            <div class="text-image__text">
              <h2>Leverage Your Social Media</h2>
              <p>The Contribution Link & Card are designed to meet your audience, on the platform of your choice. Simply place our link or button in the bio, description or feed of your preferred content network. and we’ll take it from there.</p>
            </div>
          </div>
        </div>
      </div>
<!--      <div class="text-image__background">-->
<!--        <div class="text-image__shape"></div>-->
<!--      </div>-->
    </div>
  </div>
  <section class="section">
    <div class="container container--mobile-full">
      <div class="testimonial">
        <div class="testimonial__content-wrapper">
          <div class="testimonial__content">
            <p class="testimonial__text">
              In my first week on Contribute.to, I raised enough money to hire a videographer and document behind the scenes of my work.  This allows me to think more professionally and consider a broader vision of the project.
            </p>
            <p class="testimonial__author">- <em>Mimi Haddon</em></p>
          </div>
        </div>
        <div class="testimonial__image-wrapper">
          <img src="/assets/images/mimi.jpg" alt="Nick Knight" class="testimonial__image">
        </div>
      </div>
    </div>
  </section>
  <cto-footer></cto-footer>
</div>
