import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

export enum StoreKey {
  User = 'user',
  UserProfile = 'profile',
  UserResource = 'resource',
  Merchant = 'merchant',
  MerchantId = 'merchant.id',
  Card = 'card',
  CardId = 'card.id',
  Matching = 'matching',
  Bank = 'bank',
}

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  state = new Map<StoreKey, BehaviorSubject<any>>();

  constructor() { }

  get<T>(key: StoreKey): Observable<T> {
    this.preflight<T>(key);

    return this.state.get(key).asObservable();
  }

  value<T>(key: StoreKey): T {
    this.preflight<T>(key);

    return this.state.get(key).value;
  }

  set<T>(key: StoreKey, value: T) {
    this.preflight<T>(key);

    this.state.get(key).next(value);
  }

  private preflight<T>(key: StoreKey) {
    if(this.state.has(key)) {
      return;
    }

    this.state.set(key, new BehaviorSubject<T>(null));
  }
}
