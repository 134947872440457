import {Component, OnInit} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {AuthService} from './core/services/auth.service';
import {GoogleAnalyticsService} from './core/services/google-analytics.service';

@Component({
  selector: 'cto-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('myInsertRemoveTrigger', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(50%)' }),
        animate('500ms ease-in-out', style({ opacity: 1, transform: 'translateX(0)' })),
      ]),
      transition(':leave', [
        animate('500ms ease-in-out', style({ opacity: 0, transform: 'translateX(-50%)' }))
      ])
    ]),
  ]
})
export class AppComponent implements OnInit {
  title = 'cto';

  state = {
    maxSteps: 3,
    step: 1,
    opened: false,
  };

  checks = {
    minimum: false,
    number: false,
    uppercase: false,
    special: false,
  };

  date = '';
  password = '';

  paramSub: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
    private ga: GoogleAnalyticsService,
  ) {
    this.auth.configure();

    this.router.events.subscribe(event => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }

      this.ga.config({
        page_path: event.urlAfterRedirects,
        page_title: event.urlAfterRedirects,
      });
    });
  }

  ngOnInit(): void {

  }

  // get buttonText(): string {
  //   switch (this.state.step) {
  //     case 1:
  //       return 'Next';
  //     case 2:
  //       return 'Skip';
  //     case 3:
  //       return 'Done';
  //     default:
  //       return 'Next';
  //   }
  // }

  dateKeyPress(e: KeyboardEvent): void {
    // If it's not a number, do not allow it.
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
      return;
    }

    if (this.date.length === 2 || this.date.length === 5) {
      this.date += '/';
    }
  }

  checkPassword(): void {
    this.checks = {
      minimum: this.password.length >= 10,
      number: /[0-9]/.test(this.password),
      uppercase: /[A-Z]/.test(this.password),
      special: /\W/.test(this.password),
    };
  }
}
