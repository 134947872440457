<div class="artvent">
  <div class="artvent__hero">
    <div class="container container--no-padding">
      <img src="/assets/images/artvent-cover-mobile.png" alt="ArtVent" class="artvent__background">
    </div>
  </div>
  <div class="artvent__content faq">
    <div class="container">
      <p>The season of giving is upon us!  Now, we’re not calling anyone Scrooge, but with 75% of US artists making less than $10,000 a year, don’t you think they need your support at this time of year?</p>
      <p>At Contribute.to, we celebrate ARTvent each year as an opportunity to recognize and support some of the most deserving creators on our platform. Think of it as your annual advent calendar - but for art.</p>
      <p>Check out this year’s selection of artists below, and please do share them with your family and friends!</p>
    </div>
    <div class="container artvent__newsletter">
      <p><em>Sign-up for our <a href="https://eepurl.com/hj6Do5" target="_blank">newsletter</a> to get updates from <a routerLink="/">Contribute.to</a> and potentially receive free Matching funds when you start a card.</em></p>
    </div>
  </div>
  <div class="container container--mobile-full">
    <cto-advent></cto-advent>
  </div>
</div>
<cto-footer></cto-footer>
