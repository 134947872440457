import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import Cropper from 'cropperjs';
import {CropperEvent, CropperService} from '../../../core/services/cropper.service';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {FileService} from '../../../core/services/file.service';

export type CropperType = 'avatar' | 'cover';
type CropperOptions = { [key in CropperType]: Cropper.Options };
type CropperCroppedCanvasOptions = { [key in CropperType]: Cropper.GetCroppedCanvasOptions };

@Component({
  selector: 'cto-cropper',
  templateUrl: './cropper.component.html',
  styleUrls: ['./cropper.component.scss']
})
export class CropperComponent implements OnInit, OnDestroy {
  eventSub: Subscription;
  openedSub: Subscription;
  closedSub: Subscription;
  src: SafeResourceUrl;
  opened = false;
  cropper: Cropper;
  type: CropperType;
  options: CropperOptions = {
    avatar: {
      aspectRatio: 1,
      viewMode: 2,
      dragMode: 'move',
      autoCropArea: 1,
      restore: false,
      modal: true,
      guides: false,
      highlight: false,
      cropBoxMovable: false,
      cropBoxResizable: false,
      toggleDragModeOnDblclick: false,
      center: false,
    },
    cover: {
      aspectRatio: 4 / 5,
      viewMode: 2,
      dragMode: 'move',
      autoCropArea: 1,
      restore: false,
      modal: true,
      guides: false,
      highlight: false,
      cropBoxMovable: false,
      cropBoxResizable: false,
      toggleDragModeOnDblclick: false,
      center: false,
    },
  };
  output: CropperCroppedCanvasOptions = {
    cover: {
      width: 400,
      height: 500,
    },
    avatar: {
      width: 480,
      height: 480,
    }
  }

  @ViewChild('imageElement', { static: false }) imageElement: ElementRef;

  constructor(
    private service: CropperService,
    private sanitizer: DomSanitizer,
    private file: FileService,
  ) { }

  ngOnInit(): void {
    this.eventSub = this.service.event.subscribe(async ({ event, data }) => {
      if(event === CropperEvent.None) {
        return;
      }

      if(event === CropperEvent.Close) {
        this.opened = false;
        this.cropper?.destroy();
        this.cropper = null;
        this.src = null;

        return;
      }

      if(event === CropperEvent.Open) {
        if(!data?.type || !data?.file) {
          this.opened = false;
          return;
        }

        this.cropper?.destroy();
        this.src = await this.file.readAsDataURL(data.file);
        this.type = data.type;
        this.opened = true;
        setTimeout(() => {
          this.cropper = new Cropper(this.imageElement.nativeElement, this.options[this.type]);
        }, 200);
      }
    });
  }

  ngOnDestroy() {
    this.openedSub?.unsubscribe();
    this.closedSub?.unsubscribe();
  }

  cancel() {
    this.service.emit(CropperEvent.Close);
  }

  done() {
    this.cropper.getCroppedCanvas(this.output[this.type]).toBlob(blob => {
      const date = new Date();

      (blob as any).lastModified = date;
      (blob as any).name = date.getTime() + '.jpg';

      this.service.emit(CropperEvent.Crop, blob as File);
      this.service.emit(CropperEvent.Close);
    }, 'type/jpeg');
  }
}
