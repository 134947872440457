import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../../core/services/auth.service';

@Component({
  selector: 'cto-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {
  words = ['illustrator', 'photographer', 'artist'];
  word = '';

  constructor(
    public auth: AuthService,
  ) { }

  async ngOnInit(): Promise<any> {
    await this.animate();
  }

  async animate(): Promise<any> {
    let index = 0;
    const delay = 1500;

    const write = async () => {
      await this.writeWord(this.words[index]);

      index = (index + 1) % this.words.length;

      setTimeout(write, delay);
    };

    await write();
  }

  writeWord(word: string): Promise<boolean> {
    return new Promise(resolve => {
      let index = 0;
      const speed = 80;
      this.word = '';

      const write = () => {
        if (index >= word.length) {
          resolve(true);
          return;
        }

        this.word += word.charAt(index);
        index++;
        setTimeout(write, speed);
      };

      write();
    });
  }
}

