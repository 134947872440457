<div class="advent advent--mobile">
  <div *ngFor="let item of items.mobile" class="advent__block">
    <div class="advent__item {{ item.background?.color ? 'background-' + item.background.color : '' }}">
      <ng-container *ngIf="item.link">
        <a [routerLink]="'/' + item.link.url" class="advent__link">
          <img [src]="item.link.image" alt="image" class="advent__link-image">
          <span class="advent__link-door">{{ item.link.label }}</span>
        </a>
      </ng-container>
      <ng-container *ngIf="item.triangles?.length">
        <div *ngFor="let triangle of item.triangles" class="triangle triangle--{{ triangle }}"></div>
      </ng-container>
      <ng-container *ngIf="item.background?.type === 1">
        <svg style="width: 60%; height: auto;" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M25.8946 31.5315L28.4869 27.0415L33.6715 27.0415L36.2639 31.5315L33.6715 36.0216L28.4869 36.0216L25.8946 31.5315Z" stroke="white" stroke-width="1.33333"/>
          <path d="M27.7461 34.8637L1.79297 60.8169" stroke="white" stroke-width="1.33333"/>
          <path d="M27.5264 28.1624L2.35742 2.99341" stroke="white" stroke-width="1.33333"/>
          <path d="M25.8658 31.8757L0 31.8757" stroke="white" stroke-width="1.33333"/>
          <path d="M30.959 26.7165L30.959 0" stroke="white" stroke-width="1.33333"/>
          <path d="M60.459 2.15232L34.5059 28.1055" stroke="white" stroke-width="1.33333"/>
          <path d="M59.71 60.3496L34.541 35.1807" stroke="white" stroke-width="1.33333"/>
          <path d="M1.79297 48.2976L14.3767 48.2976L14.3767 60.7703" stroke="white" stroke-width="1.33333"/>
          <path d="M14.3457 2.27637L14.3457 14.8601L1.87305 14.8601" stroke="white" stroke-width="1.33333"/>
          <path d="M60.3672 14.7818L47.7834 14.7818L47.7834 2.30908" stroke="white" stroke-width="1.33333"/>
          <path d="M47.8613 60.8496L47.8613 48.2658L60.334 48.2658" stroke="white" stroke-width="1.33333"/>
          <path d="M1.98047 53.9358L8.73964 53.9358L8.73964 60.6353" stroke="white" stroke-width="1.33333"/>
          <path d="M8.70927 2.46387L8.70927 9.22304L2.00977 9.22304" stroke="white" stroke-width="1.33333"/>
          <path d="M60.1777 9.14335L53.4186 9.14335L53.4186 2.44385" stroke="white" stroke-width="1.33333"/>
          <path d="M53.4978 60.6626L53.4978 53.9034L60.1973 53.9034" stroke="white" stroke-width="1.33333"/>
          <path d="M16.0195 39.8984L22.7787 39.8984L22.7787 46.5979" stroke="white" stroke-width="1.33333"/>
          <path d="M22.7425 16.5017L22.7425 23.2609L16.043 23.2609" stroke="white" stroke-width="1.33333"/>
          <path d="M46.1406 23.1805L39.3815 23.1805L39.3815 16.481" stroke="white" stroke-width="1.33333"/>
          <path d="M39.4626 46.6252L39.4626 39.8661L46.1621 39.8661" stroke="white" stroke-width="1.33333"/>
          <path d="M62.3599 31.6211L36.4941 31.6211" stroke="white" stroke-width="1.33333"/>
          <path d="M31.0527 62.4187L31.0527 36.553" stroke="white" stroke-width="1.33333"/>
          <path d="M7.4851 25.3391L14.0346 31.8887L7.4851 38.4382" stroke="white" stroke-width="1.33333"/>
          <path d="M37.4249 8.12316L30.8754 14.6727L24.513 8.31029" stroke="white" stroke-width="1.33333"/>
          <path d="M54.827 38.2511L48.2775 31.7015L54.6399 25.3391" stroke="white" stroke-width="1.33333"/>
          <path d="M24.513 55.4671L31.0625 48.9175L37.612 55.4671" stroke="white" stroke-width="1.33333"/>
          <path d="M19.0848 28.3332L22.6402 31.8887L19.0848 35.4441" stroke="white" stroke-width="1.33333"/>
          <path d="M34.618 19.9124L31.0625 23.4679L27.507 19.9124" stroke="white" stroke-width="1.33333"/>
          <path d="M43.2274 35.257L39.6719 31.7015L43.0402 28.3332" stroke="white" stroke-width="1.33333"/>
          <path d="M27.507 43.8654L31.0625 40.31L34.618 43.8654" stroke="white" stroke-width="1.33333"/>
        </svg>
      </ng-container>
      <ng-container *ngIf="item.background?.type === 2">
        <svg style="width: 60%; height: auto;" viewBox="0 0 62 70" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M60.7907 51.8088L1 17.2886" stroke="white" stroke-width="1.33333"/>
          <path d="M30.9121 69.0404L30.9121 0" stroke="white" stroke-width="1.33333"/>
          <path d="M1.13112 51.8771L60.9219 17.3569" stroke="white" stroke-width="1.33333"/>
          <path d="M23.5307 47.6069L23.8689 39.1686L23.8858 38.7475L23.5127 38.5516L16.0358 34.6253L23.5127 30.6991L23.8858 30.5032L23.8689 30.0821L23.5307 21.6438L30.6694 26.1558L31.0256 26.381L31.3818 26.1558L38.5205 21.6438L38.1823 30.0821L38.1655 30.5031L38.5385 30.6991L46.0154 34.6253L38.5385 38.5516L38.1655 38.7475L38.1823 39.1686L38.5205 47.6069L31.3818 43.0948L31.0256 42.8697L30.6694 43.0948L23.5307 47.6069Z" stroke="white" stroke-width="1.33333"/>
          <path d="M57.7824 40.5913L49.2748 44.9861L49.7227 54.5513" stroke="white" stroke-width="1.33333"/>
          <path d="M50.3167 14.0745L49.929 23.7498L58.502 28.2517" stroke="white" stroke-width="1.33333"/>
          <path d="M11.427 13.8272L11.8748 23.3923L3.36719 27.7871" stroke="white" stroke-width="1.33333"/>
          <path d="M3.92222 40.8614L12.4952 45.3632L12.1074 55.0386" stroke="white" stroke-width="1.33333"/>
          <path d="M59.6464 45.8816L54.8074 48.1801L55.2363 53.52" stroke="white" stroke-width="1.33333"/>
          <path d="M55.7104 15.3457L55.4394 20.5704L60.0996 22.948" stroke="white" stroke-width="1.33333"/>
          <path d="M6.17765 15.0109L6.34317 20.1988L1.76758 22.6494" stroke="white" stroke-width="1.33333"/>
          <path d="M2.32372 46.1653L6.98391 48.5429L6.71289 53.7676" stroke="white" stroke-width="1.33333"/>
          <path d="M22.821 7.65137L31.0062 12.8248L39.1914 7.65137" stroke="white" stroke-width="1.33333"/>
          <path d="M22.821 61.2246L31.0062 56.0511L39.1914 61.2246" stroke="white" stroke-width="1.33333"/>
          <path d="M26.6181 3.61792L31.0073 6.46495L35.3965 3.61792" stroke="white" stroke-width="1.33333"/>
          <path d="M26.6181 65.2573L31.0073 62.4103L35.3965 65.2573" stroke="white" stroke-width="1.33333"/>
          <path d="M22.9666 48.5852L21.75 50.6924" stroke="white" stroke-width="1.33333"/>
          <path d="M14.9775 34.5657L12.4863 34.5657" stroke="white" stroke-width="1.33333"/>
          <path d="M38.9087 48.3831L40.1543 50.5405" stroke="white" stroke-width="1.33333"/>
          <path d="M40.3042 18.5582L38.9355 20.9287" stroke="white" stroke-width="1.33333"/>
          <path d="M49.3779 34.5657L46.8867 34.5657" stroke="white" stroke-width="1.33333"/>
          <path d="M21.7056 18.5906L22.9512 20.748" stroke="white" stroke-width="1.33333"/>
        </svg>
      </ng-container>
    </div>
  </div>
</div>
<div class="advent advent--desktop">
  <div *ngFor="let item of items.desktop" class="advent__block">
    <div class="advent__item {{ item.background?.color ? 'background-' + item.background.color : '' }}">
      <ng-container *ngIf="item.link">
        <a [routerLink]="'/' + item.link.url" class="advent__link">
          <img [src]="item.link.image" alt="image" class="advent__link-image">
          <span class="advent__link-door">{{ item.link.label }}</span>
        </a>
      </ng-container>
      <ng-container *ngIf="item.triangles?.length">
        <div *ngFor="let triangle of item.triangles" class="triangle triangle--{{ triangle }}"></div>
      </ng-container>
      <ng-container *ngIf="item.background?.type === 1">
        <svg style="width: 60%; height: auto;" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M25.8946 31.5315L28.4869 27.0415L33.6715 27.0415L36.2639 31.5315L33.6715 36.0216L28.4869 36.0216L25.8946 31.5315Z" stroke="white" stroke-width="1.33333"/>
          <path d="M27.7461 34.8637L1.79297 60.8169" stroke="white" stroke-width="1.33333"/>
          <path d="M27.5264 28.1624L2.35742 2.99341" stroke="white" stroke-width="1.33333"/>
          <path d="M25.8658 31.8757L0 31.8757" stroke="white" stroke-width="1.33333"/>
          <path d="M30.959 26.7165L30.959 0" stroke="white" stroke-width="1.33333"/>
          <path d="M60.459 2.15232L34.5059 28.1055" stroke="white" stroke-width="1.33333"/>
          <path d="M59.71 60.3496L34.541 35.1807" stroke="white" stroke-width="1.33333"/>
          <path d="M1.79297 48.2976L14.3767 48.2976L14.3767 60.7703" stroke="white" stroke-width="1.33333"/>
          <path d="M14.3457 2.27637L14.3457 14.8601L1.87305 14.8601" stroke="white" stroke-width="1.33333"/>
          <path d="M60.3672 14.7818L47.7834 14.7818L47.7834 2.30908" stroke="white" stroke-width="1.33333"/>
          <path d="M47.8613 60.8496L47.8613 48.2658L60.334 48.2658" stroke="white" stroke-width="1.33333"/>
          <path d="M1.98047 53.9358L8.73964 53.9358L8.73964 60.6353" stroke="white" stroke-width="1.33333"/>
          <path d="M8.70927 2.46387L8.70927 9.22304L2.00977 9.22304" stroke="white" stroke-width="1.33333"/>
          <path d="M60.1777 9.14335L53.4186 9.14335L53.4186 2.44385" stroke="white" stroke-width="1.33333"/>
          <path d="M53.4978 60.6626L53.4978 53.9034L60.1973 53.9034" stroke="white" stroke-width="1.33333"/>
          <path d="M16.0195 39.8984L22.7787 39.8984L22.7787 46.5979" stroke="white" stroke-width="1.33333"/>
          <path d="M22.7425 16.5017L22.7425 23.2609L16.043 23.2609" stroke="white" stroke-width="1.33333"/>
          <path d="M46.1406 23.1805L39.3815 23.1805L39.3815 16.481" stroke="white" stroke-width="1.33333"/>
          <path d="M39.4626 46.6252L39.4626 39.8661L46.1621 39.8661" stroke="white" stroke-width="1.33333"/>
          <path d="M62.3599 31.6211L36.4941 31.6211" stroke="white" stroke-width="1.33333"/>
          <path d="M31.0527 62.4187L31.0527 36.553" stroke="white" stroke-width="1.33333"/>
          <path d="M7.4851 25.3391L14.0346 31.8887L7.4851 38.4382" stroke="white" stroke-width="1.33333"/>
          <path d="M37.4249 8.12316L30.8754 14.6727L24.513 8.31029" stroke="white" stroke-width="1.33333"/>
          <path d="M54.827 38.2511L48.2775 31.7015L54.6399 25.3391" stroke="white" stroke-width="1.33333"/>
          <path d="M24.513 55.4671L31.0625 48.9175L37.612 55.4671" stroke="white" stroke-width="1.33333"/>
          <path d="M19.0848 28.3332L22.6402 31.8887L19.0848 35.4441" stroke="white" stroke-width="1.33333"/>
          <path d="M34.618 19.9124L31.0625 23.4679L27.507 19.9124" stroke="white" stroke-width="1.33333"/>
          <path d="M43.2274 35.257L39.6719 31.7015L43.0402 28.3332" stroke="white" stroke-width="1.33333"/>
          <path d="M27.507 43.8654L31.0625 40.31L34.618 43.8654" stroke="white" stroke-width="1.33333"/>
        </svg>
      </ng-container>
      <ng-container *ngIf="item.background?.type === 2">
        <svg style="width: 60%; height: auto;" viewBox="0 0 62 70" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M60.7907 51.8088L1 17.2886" stroke="white" stroke-width="1.33333"/>
          <path d="M30.9121 69.0404L30.9121 0" stroke="white" stroke-width="1.33333"/>
          <path d="M1.13112 51.8771L60.9219 17.3569" stroke="white" stroke-width="1.33333"/>
          <path d="M23.5307 47.6069L23.8689 39.1686L23.8858 38.7475L23.5127 38.5516L16.0358 34.6253L23.5127 30.6991L23.8858 30.5032L23.8689 30.0821L23.5307 21.6438L30.6694 26.1558L31.0256 26.381L31.3818 26.1558L38.5205 21.6438L38.1823 30.0821L38.1655 30.5031L38.5385 30.6991L46.0154 34.6253L38.5385 38.5516L38.1655 38.7475L38.1823 39.1686L38.5205 47.6069L31.3818 43.0948L31.0256 42.8697L30.6694 43.0948L23.5307 47.6069Z" stroke="white" stroke-width="1.33333"/>
          <path d="M57.7824 40.5913L49.2748 44.9861L49.7227 54.5513" stroke="white" stroke-width="1.33333"/>
          <path d="M50.3167 14.0745L49.929 23.7498L58.502 28.2517" stroke="white" stroke-width="1.33333"/>
          <path d="M11.427 13.8272L11.8748 23.3923L3.36719 27.7871" stroke="white" stroke-width="1.33333"/>
          <path d="M3.92222 40.8614L12.4952 45.3632L12.1074 55.0386" stroke="white" stroke-width="1.33333"/>
          <path d="M59.6464 45.8816L54.8074 48.1801L55.2363 53.52" stroke="white" stroke-width="1.33333"/>
          <path d="M55.7104 15.3457L55.4394 20.5704L60.0996 22.948" stroke="white" stroke-width="1.33333"/>
          <path d="M6.17765 15.0109L6.34317 20.1988L1.76758 22.6494" stroke="white" stroke-width="1.33333"/>
          <path d="M2.32372 46.1653L6.98391 48.5429L6.71289 53.7676" stroke="white" stroke-width="1.33333"/>
          <path d="M22.821 7.65137L31.0062 12.8248L39.1914 7.65137" stroke="white" stroke-width="1.33333"/>
          <path d="M22.821 61.2246L31.0062 56.0511L39.1914 61.2246" stroke="white" stroke-width="1.33333"/>
          <path d="M26.6181 3.61792L31.0073 6.46495L35.3965 3.61792" stroke="white" stroke-width="1.33333"/>
          <path d="M26.6181 65.2573L31.0073 62.4103L35.3965 65.2573" stroke="white" stroke-width="1.33333"/>
          <path d="M22.9666 48.5852L21.75 50.6924" stroke="white" stroke-width="1.33333"/>
          <path d="M14.9775 34.5657L12.4863 34.5657" stroke="white" stroke-width="1.33333"/>
          <path d="M38.9087 48.3831L40.1543 50.5405" stroke="white" stroke-width="1.33333"/>
          <path d="M40.3042 18.5582L38.9355 20.9287" stroke="white" stroke-width="1.33333"/>
          <path d="M49.3779 34.5657L46.8867 34.5657" stroke="white" stroke-width="1.33333"/>
          <path d="M21.7056 18.5906L22.9512 20.748" stroke="white" stroke-width="1.33333"/>
        </svg>
      </ng-container>
    </div>
  </div>
</div>
