import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../core/services/auth.service';
import { RedirectActions } from '../../../core/enums/redirect-actions';
import {PreloaderService} from '../../../core/services/preloader.service';

@Component({
  selector: 'cto-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit, OnDestroy {
  routeSub: Subscription

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private loader: PreloaderService,
    ) { }

  ngOnInit(): void {
    this.loader.show();
    this.routeSub = this.route.data.subscribe(data => {
      switch (data.action) {
        case RedirectActions.Login:
          this.auth.login();
          break;
        case RedirectActions.Create:
          this.auth.signup();
          break;
        default:
          void this.router.navigate(['/']);
      }
    })
  }

  ngOnDestroy(): void {
    this.routeSub?.unsubscribe();
  }

}
