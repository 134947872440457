<header *ngIf="state.shown" class="header">
  <div [ngClass]="{'container container--small': !state.navigation }">
    <div class="header__content">
      <a routerLink="/" class="header__brand">
        <ng-container *ngIf="state.navigation">
          <img src="/assets/images/cto-logo-full-dark.svg" alt="Contribute.to Logo" class="header__logo header__logo--desktop">
          <img src="/assets/images/cto-logo-heart-dark.svg" alt="Contribute.to Logo" class="header__logo header__logo--mobile">
        </ng-container>
        <ng-container *ngIf="!state.navigation">
          <img src="/assets/images/cto-logo-full-dark.svg" alt="Contribute.to Logo" class="header__logo header__logo--desktop">
          <img src="/assets/images/cto-logo-heart-dark.svg" alt="Contribute.to Logo" class="header__logo header__logo--mobile">
          <span class="header__brand-text">Dashboard</span>
        </ng-container>
      </a>
      <!-- TODO: Hide navigation on dashboard. -->
      <nav *ngIf="state.navigation" class="header__navigation">
        <ul class="header__menu">
<!--          <li class="header__menu-item">-->
<!--            <a routerLink="/about" class="button button&#45;&#45;link-secondary">About Us</a>-->
<!--          </li>-->
          <ng-container *ngIf="state.loggedIn">
            <li class="header__menu-item">
              <a routerLink="/dashboard" class="button button--link-secondary">Dashboard</a>
            </li>
            <li class="header__menu-item">
              <a class="button button--link-secondary" (click)="auth.logout()">Log Out</a>
            </li>
          </ng-container>
          <ng-container *ngIf="!state.loggedIn">
            <li class="header__menu-item">
              <a class="button button--primary" (click)="auth.signup()">Create My Card!</a>
            </li>
            <li class="header__menu-item">
              <a class="button button--link-secondary" (click)="auth.login()">Log In</a>
            </li>
          </ng-container>
        </ul>
      </nav>
      <button *ngIf="!state.navigation" class="header__hamburger" (click)="toggleSidebar()">
        <svg *ngIf="!sidebarOpened" width="27" height="19" viewBox="0 0 27 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H27V3H0V0ZM0 8H27V11H0V8ZM27 16H0V19H27V16Z" fill="#363740"/>
        </svg>
        <svg *ngIf="sidebarOpened" width="19" height="19" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.0821739 12.9175C0.137565 12.9723 0.209912 13 0.282259 13C0.354606 13 0.426953 12.9723 0.482343 12.9175L6.49958 6.89967L12.5168 12.9169C12.5722 12.9723 12.6446 13 12.7169 13C12.7893 13 12.8616 12.9723 12.917 12.9175C13.0272 12.8073 13.0272 12.6281 12.917 12.5179L6.89919 6.50007L12.917 0.482832C13.0272 0.372615 13.0272 0.193443 12.917 0.0832273C12.8068 -0.0269889 12.6276 -0.0269889 12.5174 0.0832273L6.49958 6.10047L0.482343 0.0826621C0.372127 -0.027554 0.192955 -0.027554 0.0827391 0.0826621C-0.0274771 0.192878 -0.0274771 0.37205 0.0827391 0.482267L6.09998 6.50007L0.0821739 12.5173C-0.0280423 12.6281 -0.0280423 12.8067 0.0821739 12.9175Z" fill="#5B5B5B"/>
        </svg>
      </button>
    </div>
  </div>
</header>
