import {Component, forwardRef, OnInit} from '@angular/core';
import {ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'cto-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateInputComponent),
      multi: true
    }
  ]
})
export class DateInputComponent implements OnInit, ControlValueAccessor {
  config: any;

  data = this.fb.group({
    month: ['', Validators.required],
    day: ['', Validators.required],
    year: ['', Validators.required],
  });

  maxAge = 100;
  minAge = 14;
  currentYear = moment().year();

  onChanged: any = () => {};
  onTouched: any = () => {};

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.data.valueChanges.subscribe(value => {
      this.onChanged(this.formatValue(value));
      this.onTouched();
    });
  }

  get month() {
    return this.data.get('month').value;
  }

  get day() {
    return this.data.get('day').value;
  }

  get year() {
    return this.data.get('year').value;
  }

  get months() {
    return moment.months() || [];
  }

  get days() {
    if (!this.month) {
      return [...Array(31).keys()];
    }

    if (!this.year) {
      return [...Array(moment(`${this.month}`, 'MM').daysInMonth()).keys()];
    }

    return [...Array(moment(`${this.year}-${this.month}`, 'YYYY-MM').daysInMonth()).keys()];
  }

  get years() {
    return Array.from({ length: this.maxAge + 1 }, (_, i) => (this.currentYear - this.maxAge) + (i - this.minAge)).reverse();
  }

  formatValue({ month, day, year }: { month: string; day: string; year: string; }) {
    return this.data.valid ? `${year}-${month}-${day}` : '';
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {
    if (!obj) {
      return;
    }

    const date = obj.split('T')[0];
    const [ year, month, day ] = date.split('-');

    this.data.patchValue({ year, month, day });
  }
}
