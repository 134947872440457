import {Injectable} from '@angular/core';
import {SystemType} from '../enums/system-type';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor() { }

  randomInteger(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  isSystemType(type: SystemType): boolean {
    if(type === SystemType.iOS) {
      return [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod'
        ].includes(navigator.platform) || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
    }

    return false;
  }
}
