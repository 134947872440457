export enum SocialMedia {
  facebook = 'https://facebook.com/',
  instagram = 'https://instagram.com/',
  twitter = 'https://twitter.com/',
  tiktok = 'https://tiktok.com/',
  linkedin = 'https://linkedin.com/in/'
}

export enum SocialRegexPattern {
  facebook = '^[a-z0-9.]{5,}$',
  instagram = '^[a-z0-9.]{5,}$',
  twitter = '^@?(\\w){1,}$',
  tiktok = '^@?(\\w){1,}$',
  linkedin = '^@?(\\w){1,}$'
}
