import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {AuthService} from '../services/auth.service';
import {environment} from '../../../environments/environment';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

  constructor(
    private auth: AuthService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // TODO: Check if this will ever fail.
    // Add token if needed for the domain.
    if (!environment.tokenDomains.find(endpoint => request.url.includes(endpoint))) {
      // request.headers.delete('Authorization');

      return next.handle(request);
    }

    // TODO: Write better, this is only test.
    if(request.url.includes('single-use-token')) {
      return next.handle(request);
    }

    const tokenReq = request.clone({
      setHeaders: { Authorization: `Bearer ${this.auth.token}` },
    });

    return next.handle(tokenReq);
  }
}
