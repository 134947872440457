import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {QuestionGroup} from '../interfaces/faq';

@Injectable({
  providedIn: 'root'
})
export class FaqService {

  constructor(
    private http: HttpClient,
  ) { }

  getQuestionGroups(): Observable<QuestionGroup[]> {
    return this.http.get<QuestionGroup[]>('/assets/data/faq.json');
  }

  getAboutContent(): Observable<QuestionGroup[]> {
    return this.http.get<QuestionGroup[]>('/assets/data/about.json');
  }
}
