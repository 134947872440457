<div class="page page--no-padding-top">
  <img src="/assets/images/logo-heart.svg" alt="Contribute.to Heart Logo" class="page__logo">
  <h1 class="page__title">Tell Us Who You Are</h1>
  <div class="page__form" [class.page__form--disabled]="state.processing">
    <form (ngSubmit)="submit()" [formGroup]="form" class="user-form">
      <div class="user-form__inputs">
        <div class="form-group">
          <label class="form-label">First Name</label>
          <input type="text" class="form-text" placeholder="First Name" formControlName="first_name">
        </div>
        <div class="form-group">
          <label class="form-label">Last Name</label>
          <input type="text" class="form-text" placeholder="Last Name" formControlName="last_name">
        </div>
        <div class="form-group">
          <label class="form-label">Birth Date</label>
          <cto-date-input formControlName="dob"></cto-date-input>
<!--          <input name="date" type="date" class="form-text" placeholder="Birth Date" maxlength="10" formControlName="dob" [min]="minDate" [max]="maxDate">-->
<!--          <span class="form-message form-message&#45;&#45;error">You must be at least 13 years old.</span>-->
        </div>
        <div class="form-group">
          <label class="form-label">Country</label>
          <div class="form-select-wrapper">
            <select class="form-select" formControlName="country">
              <option value="" disabled selected>Please select...</option>
              <option *ngFor="let country of countries" [value]="country.code | uppercase">{{ country.name }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="user-form__actions">
        <button class="button button--secondary button--square button--full" [disabled]="!form.valid">{{ buttonText }}</button>
      </div>
    </form>
    <p class="page__description">
      <strong>Why are we requesting this information?</strong> <br>
      In order to collect money on Contribute.to we are required to record your legal name & date of birth.
    </p>
  </div>
</div>
