import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {FormGroup} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  state = {
    navigation: new BehaviorSubject<boolean>(true),
    header: new BehaviorSubject<boolean>(true),
  }

  constructor() { }

  isControlValid(form: FormGroup, name: string) {
    const control = form.get(name);

    if(control.pristine) {
      return true;
    }

    return control.valid;
  }

  get navigation(): Observable<boolean> {
    return this.state.navigation.asObservable();
  }

  get header(): Observable<boolean> {
    return this.state.header.asObservable();
  }

  showNavigation() {
    this.state.navigation.next(true);
  }

  hideNavigation() {
    this.state.navigation.next(false);
  }

  showHeader() {
    this.state.header.next(true);
  }

  hideHeader() {
    this.state.header.next(false);
  }
}
