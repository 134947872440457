import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CardType} from '../enums/card-type';
import {UploadUrlOptions} from '../interfaces/upload-url-options';
import {ImageUploadData, ImageUploadResponse} from '../interfaces/image.interface';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
// import {ImageUploadData} from '../interfaces/image-upload-data';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(
    private http: HttpClient,
  ) { }

  readAsDataURL(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => resolve(e.target.result);
      reader.onerror = (e) => reject(e.target.error);

      reader.readAsDataURL(file);
    });
  }

  getUploadUrls({ cardId, cardType, imageType, multiple = 1 }: UploadUrlOptions) {
    return this.http.post(`/api/v1/cards/${cardType}/${cardId}/images`, { imageType, multiple });
  }

  getUploadUrl(): Observable<ImageUploadData> {
    return this.http.post<ImageUploadData>(`${environment.url.tapper}/v1/images/upload`, {});
  }

  uploadImages() {

  }

  upload(file: File, url: string): Observable<ImageUploadResponse> {
    const data = new FormData();

    data.append('file', file);

    return this.http.post<ImageUploadResponse>(url, data);
  }
}
