import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../../core/services/auth.service';
import { Observable } from 'rxjs';
import { SocialLink } from '../../../core/interfaces/social-links';
import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs/operators';

@Component({
  selector: 'cto-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  links: SocialLink[];

  year = new Date().getFullYear();

  constructor(
    public auth: AuthService,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.getSocialLinks().pipe(first()).subscribe(links => {
      this.links = links;
    })
  }

  getSocialLinks(): Observable<SocialLink[]> {
    return this.http.get<SocialLink[]>('/assets/data/social.json');
  }

}
