<form [formGroup]="data" class="date-input-wrapper">
  <div>
    <div class="form-group">
      <div class="form-select-wrapper">
        <select class="form-select" formControlName="day">
          <option value="" disabled selected>Day</option>
          <option *ngFor="let d of days" [value]="(d + 1 < 10 ? '0' : '') + (d + 1)">{{ (d + 1 < 10 ? '0' : '') + (d + 1) }}</option>
        </select>
      </div>
    </div>
  </div>
  <div>
    <div class="form-group">
      <div class="form-select-wrapper">
        <select class="form-select" formControlName="month">
          <option value="" disabled selected>Month</option>
          <option *ngFor="let m of months; let i = index" [value]="(i + 1 < 10 ? '0' : '') + (i + 1)">{{ m }}</option>
        </select>
      </div>
    </div>
  </div>
  <div>
    <div class="form-group">
      <div class="form-select-wrapper">
        <select class="form-select" formControlName="year">
          <option value="" disabled selected>Year</option>
          <option *ngFor="let y of years" [value]="y">{{ y }}</option>
        </select>
      </div>
    </div>
  </div>
</form>
