import { Injectable } from '@angular/core';
import heic2any from 'heic2any';

@Injectable({
  providedIn: 'root'
})
export class HeicFileConverterService {
  constructor() { }

  async convert(file: File) {
    const image: File = file;

    if (!/image\/hei(c|f)/.test(image.type)) return image;

    const blob: Blob | Blob[] = await heic2any({
      blob: image,
      toType: 'image/jpeg',
      quality: 0.94,
    });

    return this.blobToFile(blob, file.name);
  }

  private blobToFile = (blob: Blob | Blob[], fileName:string): File => {
    let file: any = blob;

    file.lastModified = new Date().getTime();
    file.name = fileName;
    file.webkitRelativePath = ''

    return <File>file;
  }
}
