import { Component, OnInit } from '@angular/core';
import {first} from 'rxjs/operators';
import {FaqService} from '../../../core/services/faq.service';
import {QuestionGroup} from '../../../core/interfaces/faq';

@Component({
  selector: 'cto-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  groups: QuestionGroup[] = [];

  constructor(
    private faq: FaqService,
  ) { }

  ngOnInit(): void {
    this.faq.getAboutContent().pipe(first()).subscribe(groups => {
      this.groups = groups;
    })
  }

}
