import {ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {Card} from '../../../core/interfaces/card.interface';
import {SwiperOptions} from 'swiper';
import {BehaviorSubject} from 'rxjs';
import {SystemType} from '../../../core/enums/system-type';
import {UtilityService} from '../../../core/services/utility.service';
import {VideoPlayerService} from '../../../core/services/video-player.service';
import {SwiperComponent} from 'swiper/angular';
import {FormControl, Validators} from '@angular/forms';
import CustomValidators from '../../../core/global/custom-validators';
import {PaymentService} from '../../../core/services/payment.service';
import {environment} from '../../../../environments/environment';
import StringUtils from '../../../core/global/string-utils';

@Component({
  selector: 'cto-canonical-card',
  templateUrl: './canonical-card.component.html',
  styleUrls: ['./canonical-card.component.scss']
})
export class CanonicalCardComponent implements OnInit {
  @Input() card: Card;

  @ViewChild('swiper', { static: false }) swiper: SwiperComponent;

  config: SwiperOptions = {
    slidesPerView: 1,
    loop: true,
  };

  state = {
    expanded: false,
    isExpandable: false,
    copied: false,
    init: false,
    descriptionLimit: 120,
    selectedPrice: -1,
    paymentSuccess: false,
  }

  customValue = new FormControl('', [Validators.required, CustomValidators.price(2, 10000)]);

  image$ = new BehaviorSubject<string>('');
  overlay$ = new BehaviorSubject<boolean>(true);
  share$ = new BehaviorSubject<boolean>(false);
  contribute$ = new BehaviorSubject<boolean>(false);
  custom$ = new BehaviorSubject<boolean>(false);
  pay$ = new BehaviorSubject<boolean>(false);

  checkout: any;

  constructor(
    private utility: UtilityService,
    private player: VideoPlayerService,
    private cdr: ChangeDetectorRef,
    private payment: PaymentService,
  ) { }

  ngOnInit(): void {
  }

  get description() {
    if(!this.state.isExpandable) {
      return this.card.summary;
    }

    return this.state.expanded ? this.card.summary.replace(/\n/g, '<br>') : this.card.summary.substring(0, this.state.descriptionLimit);
  }

  get url() {
    return window.location.origin + '/' + (this.card?.slug || '');
  }

  get facebook() {
    return 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(this.url);
  }

  get twitter() {
    return 'https://twitter.com/share?url=' + encodeURIComponent(this.url);
  }

  get whatsapp() {
    return 'https://wa.me/?text=' + encodeURIComponent(this.url);
  }

  get email() {
    if(this.utility.isSystemType(SystemType.iOS)) {
      return 'sms://?&body=' + encodeURIComponent(this.url);
    }

    return 'mailto:?body=' + encodeURIComponent(this.url) + '&subject=' + encodeURIComponent('Contribute.to');
  }

  get payDisabled(): boolean {
    if(!this.custom$.value) {
      return this.state.selectedPrice < 0;
    }

    return !this.customValue.valid;
  }

  toggleOverlay() {
    this.overlay$.next(!this.overlay$.value);
  }

  onSlideChange() {
    if(!this.state.init) {
      this.state.init = true;
      return;
    }

    this.overlay$.next(false);
    this.cdr.detectChanges();
  }

  play() {
    this.player.open(this.card.video_url);
  }

  isIndex(index: number) {
    if(!this.swiper?.swiperRef) {
      return false;
    }

    return this.swiper.swiperRef.realIndex === index;
  }

  openImage() {
    // if(!this.swiper?.swiperRef) {
    //   return false;
    // }

    this.image$.next(this.card.cover_image_url);
  }

  toggleDescription() {
    if(!this.state.expanded) {
      setTimeout(() => {
        this.state.expanded = true;
      }, 0);

      return;
    }

    this.state.expanded = false;
  }

  share() {
    this.share$.next(true);
  }

  contribute() {
    this.contribute$.next(true);
  }

  closeShare() {
    this.share$.next(false);
  }

  copy() {
    void navigator.clipboard.writeText(this.url);

    this.state.copied = true;

    setTimeout(() => {
      this.state.copied = false;
    }, 1000);
  }

  closeContribute() {
    this.contribute$.next(false);
    this.state.selectedPrice = -1;
  }

  customContribute() {
    this.state.selectedPrice = -1;
    this.custom$.next(true);
  }

  cancelCustom() {
    this.custom$.next(false);
    this.customValue.patchValue('');
  }

  pay() {
    this.custom$.next(false);
    this.contribute$.next(false);
    this.pay$.next(true);

    const price = this.customValue.valid ? StringUtils.priceStringToNumber(this.customValue.value) : this.state.selectedPrice;

    if(price < 2) {
      return;
    }

    const priceInCents = Math.ceil(price * 100);

    this.payment.getSingleUseToken({
      amount: priceInCents,
      currency: 'USD',
      merchant_id: this.card.merchant_id,
    }).subscribe(async res => {
      console.log('res', res);
      this.checkout = new (window as any).ContributeTo.GuestCheckout({ testMode: environment.url.tapper.includes('sbx') });
      await this.checkout.load();

      this.checkout.render('#payment-form-1', {
        singleUseToken: res.single_use_token,
        showThankYouWidget: true,
        allowGuestRegistration: false,
        clientId: environment.authConfig.clientId,
        paymentRequestOptions: {
          country: 'US',
          currency: 'usd',
          total: {
            amount: priceInCents,
            label: 'Contribute to: ' + this.card.title,
          },
        },
        cardOptions: {
          hidePostalCode: true,
        },
        onPaymentFail: (paymentIntent, error) => {
          console.log('payment error', paymentIntent, error);
          this.closePayment();
        },
        onPaymentSuccess: paymentIntent => {
          console.log('payment success', paymentIntent);

          this.state.selectedPrice = -1;
          this.customValue.patchValue('');

          this.state.paymentSuccess = true;
          // this.state.success = true;

          // setTimeout(() => {
          //   this.closePayment();
          //   this.state.success = false;
          // }, 3000);
        },
      });
    });
  }

  closePayment() {
    this.checkout?.destroy();
    this.checkout = null;

    this.pay$.next(false);
    this.state.selectedPrice = -1;
    this.customValue.patchValue('');
    this.state.paymentSuccess = false;
  }

  closeImage() {
    this.image$.next('');
  }

  selectPrice(price: number) {
    this.state.selectedPrice = price;
  }
}
