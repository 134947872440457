import { Component, Input, OnInit } from '@angular/core';
import { SocialLink } from '../../../core/interfaces/social-links';

@Component({
  selector: 'cto-social-links',
  templateUrl: './social-links.component.html',
  styleUrls: ['./social-links.component.scss']
})
export class SocialLinksComponent implements OnInit {
  @Input() links: SocialLink[];

  constructor() { }

  ngOnInit(): void {
  }

}
