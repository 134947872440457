import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';
import {AuthService} from '../services/auth.service';
import {MerchantService} from '../services/merchant.service';
import {StoreKey, StoreService} from '../services/store.service';
import {Merchant} from '../interfaces/merchant.interface';

@Injectable({
  providedIn: 'root'
})
export class RegisterResolver implements Resolve<boolean> {
  constructor(
    private auth: AuthService,
    private merchants: MerchantService,
    private router: Router,
    private store: StoreService,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

    // Before hitting register, check for user roles.
    // This will fetch user roles (if any) and add a merchant ID
    // to the store.
    return this.merchants.getRoles().pipe(
      switchMap(() => {
        // Not really sure about this, but if merchant ID doesn't exist,
        // you should probably stay on register.
        if(!this.store.value<string>(StoreKey.MerchantId)) {
          return of(null);
        }

        // Try to get the actual merchant with merchant ID from store.
        return this.merchants.getMerchant().pipe(
          switchMap((merchant: Merchant) => {
            // If there is no first name set, assume not updates.
            // TODO: Check if this is enough.
            if(!merchant?.first_name) {
              return of(null);
            }

            this.router.navigate(['/dashboard']);
            return of(null);
          }),
        );
      }),
    );
  }

  handleError(err: any, redirect: string, provider?: string): Observable<null> {
    console.log('Dashboard resolver error:', provider || '', err);

    if(redirect) {
      this.router.navigate([redirect]);
    }

    return of(null);
  }
}
