import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PollService {
  state = {
    polling: new BehaviorSubject<boolean>(false),
  }

  constructor() { }

  get polling(): Observable<boolean> {
    return this.state.polling.asObservable();
  }

  setPolling(polling: boolean) {
    this.state.polling.next(polling);
  }
}
