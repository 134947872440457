import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {AuthService} from '../../../core/services/auth.service';
import {first, switchMap} from 'rxjs/operators';
import {MerchantService} from '../../../core/services/merchant.service';
import {countries} from '../../../core/data/countries';
import {Merchant} from '../../../core/interfaces/merchant';
import {MerchantAccount} from '../../../core/interfaces/merchant-account';
import {Router} from '@angular/router';
import {GlobalService} from '../../../core/services/global.service';
import {PreloaderService} from '../../../core/services/preloader.service';
import {StoreKey, StoreService} from '../../../core/services/store.service';
import {of} from 'rxjs';

@Component({
  selector: 'cto-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit, OnDestroy {
  countries = countries;
  account: MerchantAccount;
  merchant: Merchant;
  date = new Date();

  state = {
    processing: false,
  }

  form = this.fb.group({
    country: ['', Validators.required],
    first_name: ['', Validators.required],
    last_name: ['', Validators.required],
    dob: ['', Validators.required],
    url: ['https://contribute.to/'],
  });

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private merchants: MerchantService,
    private router: Router,
    private global: GlobalService,
    private loader: PreloaderService,
    private store: StoreService,
  ) { }

  get buttonText() {
    return this.state.processing ? 'Processing...' : 'Submit';
  }

  get minDate() {
    return (this.date.getFullYear() - 150) + '-01-01';
  }

  get maxDate() {
    return (this.date.getFullYear() - 13) + '-12-31';
  }

  ngOnInit(): void {
    this.global.hideHeader();
    this.merchants.getMerchant().pipe(first()).subscribe(
      async merchant => {
        if(!merchant) {
          return;
        }

        this.merchant = merchant;
        console.log('GET /merchants', merchant);
        await this.router.navigate(['/dashboard']);
      },
      () => {},
    );
  }

  createMerchant() {
    this.loader.show();

    const merchantId = this.store.value<Merchant>(StoreKey.Merchant)?.id;

    // If merchant ID exists, that means that an empty
    // merchant was already created. Just update it.
    if(merchantId) {
      this.updateMerchant(merchantId);
      return;
    }

    this.merchants.createMerchant().pipe(
      first(),
      switchMap((merchant: Merchant) => {
        if(!merchant?.id) {
          return of(null);
        }

        this.updateMerchant(this.store.value<Merchant>(StoreKey.Merchant)?.id);

        return of(null);
      }),
    ).subscribe();
  }

  updateMerchant(id?: string) {
    this.state.processing = true;

    this.merchants.updateMerchant(this.form.value, id).subscribe(async res => {
      console.log('PUT /merchants', res);

      let timeout = null;
      let threshold = 500;

      this.merchants.getRoles().pipe(first()).subscribe(() => {
        const poll = () => {
          if(timeout) {
            clearTimeout(timeout);
          }

          this.merchants.getMerchant(id).pipe(first()).subscribe(async (merchant: Merchant) => {
            console.log('merchant', merchant);
            if(!(merchant?.payouts_enabled || merchant?.transfers_enabled)) {
              console.log('polling...');

              timeout = setTimeout(() => poll(), threshold);
              return;
            }

            await this.router.navigate(['/dashboard']);
            this.state.processing = false;
          });
        };

        poll();
      });
    });
  }

  submit() {
    if(!this.form.valid) {
      return;
    }

    // if(!this.merchant) {
    //   this.createMerchant();
    //   return;
    // }

    this.createMerchant();
  }

  ngOnDestroy() {
    this.global.showHeader();
  }
}
