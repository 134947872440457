import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import StringUtils from './string-utils';

export default class CustomValidators {
  static price(min?: number, max?: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if(control.value == null) {
        return { invalid: control.value };
      }

      const price = StringUtils.priceStringToNumber(control.value);

      if (Number.isNaN(price)) {
        return { invalid: control.value };
      }

      if(min && price < min) {
        return { invalid: control.value };
      }

      if(max && price > max) {
        return { invalid: control.value };
      }

      return null;
    };
  }

  static url(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if(control.value == null) {
        return null;
      }

      if(!control.value?.length) {
        return null;
      }

      if(/^(?:https?:\/\/)?[\w.-_]+\.[\w.-_]+(.*)*/.test(control.value.trim())) {
        return null;
      }

      return { invalid: control.value };
    }
  }
}
