import { Component, OnInit } from '@angular/core';

interface AdventItem {
  link?: {
    label: string;
    url: string;
    image?: string;
  }
  triangles?: string[];
  background?: {
    type: 1 | 2,
    color: string;
  };
}

type Advent = AdventItem[];

@Component({
  selector: 'cto-advent',
  templateUrl: './advent.component.html',
  styleUrls: ['./advent.component.scss']
})
export class AdventComponent implements OnInit {
  artists: AdventItem[] = [
    { link: { label: '1', url: 'mimihaddon', image: 'https://contribute.to/cdn/614e1a8b27758f00119e7e3f' } },
    { link: { label: '2', url: 'markus-green', image: 'https://contribute.to/cdn/6172bd57b4e1480012f0f722' } },
    { link: { label: '3', url: 'andrewblauschild', image: 'https://contribute.to/cdn/6151a62427758f00119e8b6e' } },
    { link: { label: '4', url: 'sheenalennox', image: 'https://contribute.to/cdn/6165871db4e1480012f04e4e' } },
    { link: { label: '5', url: 'blairehawes', image: 'https://contribute.to/cdn/615c6fe0b4e1480012efdb83' } },
    { link: { label: '6', url: 'cosminstan', image: 'https://contribute.to/cdn/618d347eb4e1480012f7bfaf' } },
    { link: { label: '7', url: 'geraldjenkins', image: 'https://contribute.to/cdn/614e0ef327758f00119e768b' } },
    { link: { label: '8', url: 'minlee', image: 'https://contribute.to/cdn/61545d54b4e1480012ef6112' } },
    { link: { label: '9', url: 'silvialelli', image: 'https://contribute.to/cdn/614e190727758f00119e7971' } },
    { link: { label: '10', url: 'downsandtowns', image: 'https://contribute.to/cdn/61891100b4e1480012f79234' } },
    { link: { label: '11', url: 'bertoherrera', image: 'https://contribute.to/cdn/6151a86427758f00119e8fcd' } },
    { link: { label: '12', url: 'simona-ruscheva', image: 'https://contribute.to/cdn/615d8a9bb4e1480012effb48' } },
    { link: { label: '13', url: 'alligator', image: 'https://contribute.to/cdn/614e286527758f00119e88ee' } },
    { link: { label: '14', url: 'momseattle', image: 'https://contribute.to/cdn/61826dbbb4e1480012f77854', } },
    { link: { label: '15', url: 'brittlloyd', image: 'https://contribute.to/cdn/614e1e6e27758f00119e81f1' } },
    { link: { label: '16', url: 'rdelatorre', image: 'https://contribute.to/cdn/6183f5e0b4e1480012f7824f' } },
    { link: { label: '17', url: 'gabrielemilani', image: 'https://contribute.to/cdn/6151b79727758f00119ea4ba' } },
    { link: { label: '18', url: 'margretwibmer', image: 'https://contribute.to/cdn/619837bbb4e1480012f80295' } },
    { link: { label: '19', url: 'bindivora', image: 'https://contribute.to/cdn/6151b53a27758f00119ea072' } },
    { link: { label: '20', url: 'rick-cotter', image: 'https://contribute.to/cdn/619e686eb4e1480012f82263' } },
    { link: { label: '21', url: 'gabrielsalvador', image: 'https://contribute.to/cdn/614e232e27758f00119e8586' } },
    { link: { label: '22', url: 'evelynmorgan', image: 'https://contribute.to/cdn/619a8bf7b4e1480012f80dbb' } },
    { link: { label: '23', url: 'kristylund', image: 'https://contribute.to/cdn/618bc910b4e1480012f7af94' } },
    { link: { label: '24', url: 'halaburda', image: 'https://contribute.to/cdn/6151a51827758f00119e8ace' } },
  ];

  items: { desktop: Advent; mobile: Advent; } = {
    desktop:  [
      // 1
      this.artists[0],
      { triangles: ['upper-left-yellow'] },
      this.artists[1],
      { background: { type: 1, color: 'yellow' } },
      this.artists[2],
      { triangles: ['upper-right-green', 'lower-left-yellow'] },
      this.artists[3],
      { triangles: ['upper-right-yellow', 'lower-left-red'] },

      // 2
      { triangles: ['upper-right-yellow', 'lower-left-red'] },
      this.artists[4],
      { background: { type: 2, color: 'green' } },
      this.artists[5],
      { triangles: ['upper-left-red', 'lower-right-yellow'] },
      this.artists[6],
      { background: { type: 1, color: 'yellow' } },
      this.artists[7],

      // 3
      this.artists[8],
      { triangles: ['upper-left-green', 'lower-right-yellow'] },
      this.artists[9],
      { triangles: ['lower-left-yellow'] },
      this.artists[10],
      { triangles: ['upper-right-yellow'] },
      this.artists[11],
      { background: { type: 1, color: 'red' } },

      // 4
      { background: { type: 1, color: 'red' } },
      this.artists[12],
      { triangles: ['upper-right-yellow', 'lower-left-red'] },
      this.artists[13],
      { triangles: ['lower-right-yellow', 'upper-left-green'] },
      this.artists[14],
      { triangles: ['upper-left-red'] },
      this.artists[15],

      // 5
      this.artists[16],
      { triangles: ['upper-left-yellow'] },
      this.artists[17],
      { triangles: ['upper-left-green', 'lower-right-red'] },
      this.artists[18],
      { background: { type: 2, color: 'green' } },
      this.artists[19],
      { triangles: ['lower-left-green', 'upper-right-yellow'] },

      // 6
      { triangles: ['upper-left-green', 'lower-right-red'] },
      this.artists[20],
      { background: { type: 1, color: 'yellow' } },
      this.artists[21],
      { triangles: ['lower-left-green'] },
      this.artists[22],
      { triangles: ['upper-left-red', 'lower-right-green'] },
      this.artists[23],
    ],
    mobile:  [
      // 1
      this.artists[0],
      { triangles: ['upper-left-yellow'] },
      this.artists[1],
      { background: { type: 1, color: 'green' } },

      //2
      { background: { type: 2, color: 'red' } },
      this.artists[2],
      { triangles: ['upper-left-red', 'lower-right-yellow'] },
      this.artists[3],

      // 3
      this.artists[4],
      { triangles: ['lower-left-yellow', 'upper-right-green'] },
      this.artists[5],
      { triangles: ['upper-right-yellow', 'lower-left-green'] },

      // 4
      { triangles: ['upper-left-red', 'lower-right-yellow'] },
      this.artists[6],
      { background: { type: 1, color: 'yellow' } },
      this.artists[7],

      // 5
      this.artists[8],
      { triangles: ['lower-left-red'] },
      this.artists[9],
      { triangles: ['upper-left-green', 'lower-right-red'] },

      // 6
      { background: { type: 2, color: 'green' } },
      this.artists[10],
      { triangles: ['upper-right-yellow'] },
      this.artists[11],

      // 7
      this.artists[12],
      { background: { type: 1, color: 'yellow' } },
      this.artists[13],
      { triangles: ['upper-left-green', 'lower-right-yellow'] },

      // 8
      { triangles: ['lower-left-green', 'upper-right-red'] },
      this.artists[14],
      { triangles: ['upper-left-red'] },
      this.artists[15],

      // 9
      this.artists[16],
      { triangles: ['lower-left-yellow'] },
      this.artists[17],
      { background: { type: 2, color: 'green' } },

      // 10
      { triangles: ['upper-left-green'] },
      this.artists[18],
      { triangles: ['lower-left-green', 'upper-right-yellow'] },
      this.artists[19],

      // 11
      this.artists[20],
      { background: { type: 1, color: 'red' } },
      this.artists[21],
      { triangles: ['lower-right-green'] },

      // 12
      { triangles: ['lower-left-green', 'upper-right-yellow'] },
      this.artists[22],
      { triangles: ['upper-left-yellow', 'lower-right-red'] },
      this.artists[23],
    ],
  }

  constructor() { }

  ngOnInit(): void {
  }

}
