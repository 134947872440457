import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';

declare const gtag: (...args) => void;

interface GoogleAnalyticsEventData {
  event_category?: string;
  event_label?: string;
  value?: number;
}

interface GoogleAnalyticsConfigData {
  page_title?: string;
  page_location?: string;
  page_path?: string;
}

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor() {
    gtag('config', environment.googleAnalyticsId);
  }

  private static formatActionValue(value: string) {
    value = value || '';

    return value && (': ' + value);
  }

  static actionButtonClick(value?: string) {
    return 'User clicks button' + GoogleAnalyticsService.formatActionValue(value);
  }

  static actionContributePreset(value?: string) {
    return 'User wants to contribute with preset amount' + GoogleAnalyticsService.formatActionValue(value);
  }

  static actionContributeCustom(value?: string) {
    return 'User wants to contribute with custom amount' + GoogleAnalyticsService.formatActionValue(value);
  }

  static actionCreditCardModal(value?: string) {
    return 'User sees Credit Card Entry view' + GoogleAnalyticsService.formatActionValue(value);
  }

  static actionPaymentSuccessful(value?: string) {
    return 'User payment successful' + GoogleAnalyticsService.formatActionValue(value);
  }

  static actionSocialMediaClick(value?: string) {
    return 'User clicks social media button' + GoogleAnalyticsService.formatActionValue(value);
  }

  static actionShare(value?: string) {
    return 'User shares card via' + GoogleAnalyticsService.formatActionValue(value);
  }

  static actionOpenVideo(value?: string) {
    return 'User opens video' + GoogleAnalyticsService.formatActionValue(value);
  }

  static actionDescriptionExpanded(value?: string) {
    return 'User expanded description' + GoogleAnalyticsService.formatActionValue(value);
  }

  config(data: GoogleAnalyticsConfigData) {
    const { page_title, page_location, page_path } = data;

    gtag('config', environment.googleAnalyticsId, {
      page_title: page_title ? 'contribute.to: ' + page_title : document.title,
      page_location: page_location || location.href,
      page_path: page_path || location.pathname,
    });

    console.log('[GTAG | Config]:', data);
  }

  event(action: string, data?: GoogleAnalyticsEventData) {
    gtag('event', action, data || {});
    console.log('[GTAG | Event]:', action, data);
  }
}
